import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  WritableSignal,
  effect,
  signal,
  untracked,
} from "@angular/core";
// Ol Imports
import OlGeolocation from 'ol/Geolocation.js';
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlTopoJSON from "ol/format/GeoJSON";
import OlType, { Type } from "ol/geom/Geometry";
import Draw from 'ol/interaction/Draw';
import OlVectorLayer from "ol/layer/Vector";
import { register } from 'ol/proj/proj4.js';
import OlVectorSource from "ol/source/Vector";

import SearchFeature from "ol-ext/control/SearchFeature";
import OlPolygon from "ol/geom/Polygon";
import OlSelect from "ol/interaction/Select";
import OlFill from "ol/style/Fill";
import OlStroke from "ol/style/Stroke";
import OlStyle from "ol/style/Style";

import { Modify, Snap, defaults as defaultInt } from "ol/interaction";

import { defaults } from "ol/control";
import OlAttribution from "ol/control/Attribution";
import OlFullScreen from "ol/control/FullScreen";
import { pointerMove, singleClick } from "ol/events/condition";
import OSMXML from "ol/format/OSMXML";
import OlTileLayer from "ol/layer/Tile";
import OlBingMaps from "ol/source/BingMaps";
import CircleStyle from "ol/style/Circle";

import { fromLonLat, toLonLat } from "ol/proj";

import * as proj4 from 'proj4';
import shp from 'shpjs';

import { ToastrService } from "ngx-toastr";
import { delay, filter, first, take } from "rxjs/operators";
import { APIService } from "src/app/services/APIService/api.service";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";

import { KeyValuePipe, NgClass, NgFor, NgIf, NgStyle, NgTemplateOutlet, TitleCasePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ClrAccordionModule, ClrCheckboxModule, ClrCommonFormsModule, ClrConditionalModule, ClrDatagridModule, ClrDropdownModule, ClrIconModule, ClrModalModule, ClrPopoverHostDirective, ClrRadioModule, ClrSelectModule, ClrStopEscapePropagationDirective, ClrTreeViewModule } from "@clr/angular";
import JSZip from 'jszip';
import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { Geometry, LineString, Point } from "ol/geom";
import { getLength as geoDesLength, getLength } from "ol/sphere";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import OlText from "ol/style/Text";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { duplicate } from "src/app/misc/functions";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { ObjTypePipe } from "src/app/pipes/objtype.pipe";
import { AuthService } from "src/app/services/Auth/auth.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { PersistanceService } from "src/app/services/localstorage.service";
import { environment } from "src/environments/environment";
import { DotDotDotPipe } from "../../pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "../../pipes/hide-idcolumns.pipe";
import { MrTranslatePipe as MrTranslatePipe_1 } from "../../pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "../../pipes/tablePrettyPrint.pipe";
import { DetailviewComponent, getTokenRight } from "../_components/detailview/detailview.component";
import { ProgressbarComponent } from "../_components/progressbar/progressbar.component";
import { GleismessdatenmodalComponent } from "./gleismessdatenmodal/gleismessdatenmodal.component";
import { ElementStylesObj, TileLayerObj } from "./ol-exports";

const production = true;
const retina =
  window.devicePixelRatio > 1 ? (window.devicePixelRatio > 2 ? 3 : 2) : 1;

@Component({
    selector: "app-gismap",
    templateUrl: "./gismap.component.html",
    styleUrls: ["./gismap.component.scss"],
    providers: [PersistanceService],
    imports: [
        NgClass,
        ClrIconModule,
        NgIf,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrDropdownModule,
        ClrConditionalModule,
        ClrAccordionModule,
        ClrDatagridModule,
        ClrTreeViewModule,
        NgFor,
        ClrSelectModule,
        ClrCommonFormsModule,
        FormsModule,
        NgStyle,
        NgTemplateOutlet,
        GleismessdatenmodalComponent,
        ClrCheckboxModule,
        DetailviewComponent,
        ProgressbarComponent,
        ClrModalModule,
        ReactiveFormsModule,
        ClrRadioModule,
        TitleCasePipe,
        KeyValuePipe,
        TablePrettyPrintPipe,
        HideIDColumnsPipe,
        DotDotDotPipe,
        MrTranslatePipe_1,
    ],
    standalone: true
})
export class GISMapComponent implements AfterViewInit, OnDestroy, OnInit {
  _ostammid: number = undefined;
  set ostammid(val: number) {
    if (val) {
      if (!this.showdetailview) {
        this.showdetailview = true;
      }
      this.map && this.updateMapSize();
      this._ostammid = val;
    } else {
      this._ostammid = undefined;
    }
  }

  get ostammid() {
    return this._ostammid;
  }

  modalLinkGroup = new UntypedFormGroup({
    objTyp: new UntypedFormControl("", [Validators.required]),
    StammID: new UntypedFormControl("", [Validators.required]),
  });

  modalTOEditor = new UntypedFormGroup({
    WeichenTyp: new UntypedFormControl("", [Validators.required]),
    Bezeichnung: new UntypedFormControl("", [Validators.required]),
    BezPosition: new UntypedFormControl("", [Validators.required]),
    Position: new UntypedFormControl("", [Validators.required]),
    richtung: new UntypedFormControl(""),
    PositionCut: new UntypedFormControl(""),
    PositionMouse: new UntypedFormControl("")
  });

  tb1: string;
  tb2: string;
  tb3: string;
  tb4: string;
  tb5: string;
  tb6: string;

  showoverlay: boolean = false;
  showdetailview: boolean = false;
  hidedetailview: boolean = false;
  showprogressbar: boolean = false;
  prevangle = 0;
  rownr = 14;
  len:number = 0;
  lenSphere:number = 0;
  featLen:string = "0";
  curEPSG:string = "EPSG:4326"
  split50 = false;
  sidenavenlarged = false;
  progressbarenlarged = false;
  tblAnl:any;
  selTbl:any;
  baseColor: string = "rgba(0,0,0,0.6)";
  hoverColor: string = "#fe5d00";
  hoverEmptyColor: string = "#FEAC88";

  private readonly action: WritableSignal<"draw" | "cut" |  "separate" |  "merge" |  "place" |  "placeTO" |  "rotate" |  "move" |  "modify" |  "getPos" | "measure" | "sort" | "drawarc" |  "moveAll" | ""> = signal(undefined);
  readonly #_actionEffect = effect(() => {
    const action = this.action();
    untracked(() => {
      this.hidedetailview = action != "";
    });
  })


  isObjLinkVisible = false;
  isTurnoutEditorVisible = false;
  isPositionModalVisible = false;
  isGeoPosModalVisible = false;
  isMapSetNameVisible = false;
  isnewMapSet = false;
  measure :any = {
    SetSecondPoint: false,
    Point1:{},
    Point2:{},
    Point1_Deg:{},
    Point2_Deg:{},
    PointX:"",
    PointY:"",
    Distance:""
  };
  markers = [];
  arcShowDlgOnly = false;
  enableSave = false;
  altPressed = false;
  shiftPressed = false;
  selTOType  = 0;
  selTOObject  = "";
  curTOPic = "";
  lstWeichen:any;
  hasIntIDFound = false;

  curObjTyp = 0;
  curSymbol = "0";
  curMapSet = "1";
  selObjID = 0;
  tblObjektTypen : any;
  tblWeichenTypen : any;
  tblKartenset: any;
  progressbars: any;
  componentStore = {};
  tempInteractions =  [];

  showObjText: boolean;
  showMangelBem: boolean;

  indexScrub: number = -1;
  overlayref;
  overlayZ = 3;
  overLayIds: any[] = [];
  baseLayer: any = [];
  drawInteractions: any = [];
  editInteractions: any = [];
  activeElement: any;
  selectedFeat: Feature;
  tempfeaturesList:any = [];
  protected readonly lstWeichenTypen: any = {0:"EW - Einfache Weichen",1:"Sym. ABW - Sym. Außenbogenweichen",2:"ABW - Außenbogenweichen",
  3:"EKW - Einfache Kreuzungsweichen",4:"DKW - Doppelte Kreuzungsweichen",5:"KR - Kreuzung"}; //,6:"IBW - Innenbogenweiche"
  prevTarget:any;
  mousepos:any;
  mouseposConv:any;
  dlgRadius:any;
  setPosFeatOnce: boolean = false;

  showOverlaySwitcher: boolean = false;

  layers: any[] = [];

  vectorLayerNames = [
    "base",
    "objname",
    "points",
    "maengel.1",
    "maengel.2",
    "maengel.3",
    "maengel.4",
    "minfk.1",
    "minfk.2",
    "minfk.3",
    "minfk.4",
  ];
  visuColors: any = {};
  features: any;

  objectFilter = [];
  datefilter: any = {
    vom: null,
    bis: null,
  };
  filter_invert = false;
  filter_modal = false;

  visumode;

  Featurecache = new Map<string, Feature[]>();
  ind: number = 0;

  public geolocation = new OlGeolocation({
    // enableHighAccuracy must be set to true to have the heading value.
    trackingOptions: {
      enableHighAccuracy: true,
    },
    //projection: this.map.view.getProjection(),
  });

  accuracyFeature = new Feature();


  positionFeature = new Feature();
  positionStyle = new OlStyle();
  drawarcFeature = new Feature();
  map: any;

  topojsonLayer: OlVectorLayer<any> = new OlVectorLayer({
    source: new OlVectorSource({
      overlaps: false,
      format: new OlTopoJSON(),
      loader: (extent, resolution, projection) => {
        var xhr = new XMLHttpRequest();
        let token = localStorage.getItem("jwt");
        xhr.open("GET", environment.apiUrl + "/karte/topojson", true);
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        var onError = () => {
          this.topojsonLayer.getSource().removeLoadedExtent(extent);
        };
        xhr.onerror = onError;
        xhr.onload = () => {
          if (xhr.status == 200) {
            let src = this.topojsonLayer.getSource();
            setTimeout(() => {
              let features = src.getFormat().readFeatures(xhr.responseText, {
                featureProjection: "EPSG:3857",
              });
              src.addFeatures(features);
              this.topojsonready$.next(true);
            });
          } else {
            onError();
          }
        };
        xhr.send();
      },
    }),
    style: this.getLayerStyles(),
  });

  gleisMessFilter: any = [];
  isGleisMessFilterReady = false;

  posLayer: OlVectorLayer<any>;
  importLayer: OlVectorSource<any>;
  drawVector: OlVectorLayer<any> = new OlVectorLayer({
    source: new OlVectorSource({wrapX: false}),
  });
  modPointsLayer: OlVectorLayer<any> = new OlVectorLayer({
    source: new OlVectorSource({wrapX: false}),
    style: this.modPointStyleFunction()
    });
  gleisMessLayer: OlVectorLayer<any> = new OlVectorLayer({
    source: new OlVectorSource({
      overlaps: false,
      format: new OlTopoJSON(),
      loader: (extent, resolution, projection) => {
        if (this.gleisMessFilter.length > 0) {
          var xhr = new XMLHttpRequest();
          let token = localStorage.getItem("jwt");
          xhr.open("POST", environment.apiUrl + "/karte/gleismessdaten", true);
          xhr.setRequestHeader("Authorization", "Bearer " + token);
          xhr.setRequestHeader(
            "Content-Type",
            "application/json;charset=UTF-8"
          );
          //xhr.setRequestHeader("Cache-Control", "no-cache");
          xhr.setRequestHeader("Cache-Control", "no-store");

          var onError = () => {
            this.gleisMessLayer.getSource().removeLoadedExtent(extent);
          };
          xhr.onerror = onError;
          xhr.onload = () => {
            if (xhr.status == 200) {
              let src = this.gleisMessLayer.getSource();
              setTimeout(() => {
                let features = src.getFormat().readFeatures(xhr.responseText, {
                  featureProjection: "EPSG:3857",
                });
                src.addFeatures(features);
                this.spinner.disable();
                this.isGleisMessFilterReady = true;
              });
            } else {
                this.isGleisMessFilterReady = true;
                onError();
            }
          };
          xhr.send(JSON.stringify(this.gleisMessFilter));
        } else {
          this.spinner.disable();
          this.isGleisMessFilterReady = true;
        }
      },
    }),
    style: () => {
      //let dark = (this.baseLayer[1] == 'cartoDbDark' || this.baseLayer[1] == 'cartoDbDarkNoLabels' || this.baseLayer[1] == 'mapboxDark');
      //let baseColor = dark ? 'rgba(170,170,170,0.6)' : 'rgba(0,0,0,0.6)';
      this.elementStyles.line.getStroke().setColor("red");
      this.elementStyles.line.getStroke().setLineDash([]);
      return this.elementStyles.line;
    },
  });


  tileLayers = TileLayerObj;
  elementStyles = ElementStylesObj;

  overLayers: any = {
    base: [
      {
        category: "Base Overlays",
        overlays: [],
      },
      {
        category: "Fehlerklassen einfach",
        overlays: [
          {
            name: "FK 1",
            key: "minfk.1",
            enable: false,
            color: "red",
          },
          {
            name: "FK 2",
            key: "minfk.2",
            enable: false,
            color: "yellow",
          },
          {
            name: "FK 3",
            key: "minfk.3",
            enable: false,
            color: "green",
          },
          {
            name: "FK 4",
            key: "minfk.4",
            enable: false,
            color: "blue",
          },
        ],
      },
      {
        category: "Fehlerklassen segmentiert",
        overlays: [
          {
            name: "FK 1",
            key: "maengel.1",
            enable: false,
            color: "red",
          },
          {
            name: "FK 2",
            key: "maengel.2",
            enable: false,
            color: "yellow",
          },
          {
            name: "FK 3",
            key: "maengel.3",
            enable: false,
            color: "green",
          },
          {
            name: "FK 4",
            key: "maengel.4",
            enable: false,
            color: "blue",
          },
        ],
      },
    ],
    visualisations: {},
  };

  overLayersOriginal = duplicate(this.overLayers);

  draw: Draw; // global so we can remove it later
  snap: any;

  clickSelect: any;
  hoverSelect: any;
  modify: any;
  Object = Object;

  subscriptions: Subscription[] = [];

  @ViewChild("datagridwrapper", { static: true }) datagridwrapper: ElementRef;

  mapready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  topojsonready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  activeElement$ = new BehaviorSubject<Feature<Geometry>>(undefined);
  mapZoomed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private apiservice: APIService,
    private authService: AuthService,
    private basicStore: BasicStoreService,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private changeDet: ChangeDetectorRef,
    private router: Router,
    private mrTranslate: MrTranslatePipe,
    private route: ActivatedRoute,
    private persistanceService: PersistanceService,
    private objtypePipe: ObjTypePipe
  ) {
    // subscribe to when mapready and topojsonready are both true
    combineLatest([this.mapready$, this.topojsonready$, this.activeElement$])
      .pipe(
        filter(([mapready, topojsonready, activeElement]) => {
          return mapready && topojsonready && activeElement !== undefined;
        }),
        take(1)
      )
      .subscribe(() => {
        this.selectFeature(this.activeElement$.getValue());
      });
  }

  private updateMapSize() {
    this.map.updateSize();
    window.dispatchEvent(new Event("resize"));
  }

  addInteraction(type:string) {
    let value = type;
    let oltyp = new OlType();
    if (value !== 'None') {
      this.draw = new Draw({
        source: this.drawVector.getSource(),
        type: <Type>type
      });

      this.draw.on("drawabort",(e) => {
        this.action.set("");
        this.map.removeInteraction(this.draw);
      });

      this.draw.on("drawend",(e) => {
        this.action.set("");
        if(this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
        this.prevTarget = undefined;
        this.map.removeInteraction(this.draw);
        e.feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');
        let c :any  = {};
        c.koordinaten = (e.feature as Feature<LineString>).getGeometry().getCoordinates();
        c.typid = 1;
        this.map.getViewport().style.cursor = "grab";
        this.apiservice
        .setNewFeature(c)
        .pipe()
        .subscribe((res: any) => {
          let src = this.topojsonLayer.getSource();
          let features = src.getFormat().readFeature(res, {
            featureProjection: "EPSG:3857",
          });
          this.tempfeaturesList.push({type:'new_Line', feature: res});
          src.addFeature(features);
          this.enableSave = true;
        });
      });

      if (this.editInteractions.length == 0) {
        this.editInteractions = this.map.interactions.array_;
      }

      this.map.removeInteraction('Draw');
      this.map.removeInteraction('Snap');
      this.map.interactions.push(this.draw);
      this.snap = new Snap({
        source: this.topojsonLayer.getSource(),
      });
      this.map.addInteraction(this.snap);
    }
  }

  startDraw(type:string, event:any) {
    this.action.set("draw");
    this.map.getViewport().style.cursor = "crosshair";
    if(this.prevTarget === event.currentTarget || type == 'None') {
      this.action.set("");
      if(type == 'None') this.prevTarget.style.backgroundColor = "#F0F0F0";
      this.prevTarget = undefined;
      event.currentTarget.style.backgroundColor = "#F0F0F0";
      this.map.removeInteraction(this.draw);
      this.map.removeInteraction(this.snap);
      this.addInteraction('None');
      return;
    }
    if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
    event.currentTarget.style.backgroundColor = "#9E9E9E";
    this.prevTarget = event.currentTarget;

    this.map.removeInteraction(this.draw);
    this.map.removeInteraction(this.snap);
    this.addInteraction(type);
  };
  startDrawArc(showonlyDialog: any, event:any){
    this.map.getViewport().style.cursor = "crosshair";
    if(this.action() != "drawarc") {
      if(this.action() == "draw") {
        this.draw.abortDrawing();
      }
      this.arcShowDlgOnly = showonlyDialog;
      this.action.set("drawarc");
      this.markers = [];
      this.drawarcFeature = undefined;

      if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
      event.currentTarget.style.backgroundColor = "#9E9E9E";
      this.prevTarget = event.currentTarget;
    } else {
      this.action.set("");
      this.arcShowDlgOnly = false;
      let src = this.topojsonLayer.getSource();
      src.removeFeature(this.drawarcFeature);
      this.prevTarget.style.backgroundColor = "#F0F0F0";
    }
  }
  startPlaceSymbol(type:number, event:any) {
    this.action.set("place");
    this.map.getViewport().style.cursor = "crosshair";
    this.curSymbol = type.toString();
    if(this.prevTarget === event.currentTarget) {
      this.action.set("");
      this.prevTarget = undefined;
      event.currentTarget.style.backgroundColor = "#F0F0F0";
      this.map.getViewport().style.cursor = "grab";
      return;
    }
    if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0"
    event.currentTarget.style.backgroundColor = "#9E9E9E"
    this.prevTarget = event.currentTarget;
  }

  updateSelected(e:any){
    if(this.activeElement) {
      this.isObjLinkVisible = true;

    } else {
      this.toastr.warning(
        this.mrTranslate.transform("Kein Objekt ausgewählt")
      );
    }
  }

  showTOEditor(e: any) {
    this.isTurnoutEditorVisible = true;
    this.modalTOEditor.controls.richtung.setValue(0);
    this.modalTOEditor.controls.WeichenTyp.setValue(0);
    setTimeout(() => {
      this.getTOData('0');
      this.modalTOEditor.controls.Bezeichnung.setValue("EW 50-1:3,25");
    }, 200);
  }

  getTOData(value: any) {
    var tb1 = document.getElementById('tb1');
    var tb2 = document.getElementById('tb2');
    var tb3 = document.getElementById('tb3');
    var tb4 = document.getElementById('tb4');
    var tb5 = document.getElementById('tb5');
    var tb6 = document.getElementById('tb6');
    this.selTOType = value;
    this.apiservice
      .getTOEdit(value)
      .pipe()
      .subscribe((res: any) => {
        if (res) {
          this.tblWeichenTypen = res;
          tb4.style.display = "";
          tb6.style.display = "none";
          this.lstWeichen = [];
          this.curTOPic = "assets/icons/Weicheneditor/";
          switch (value) {
            case '0':
              this.curTOPic += "EW_L.png"
              this.lstWeichen.push("EW 50-1:3,25","EW 100-1:5","EW 140-1:6","EW 190-1:9","EW 150-1:6.6","EW 190-1:6.6","EW 300-1:9","EW 500-1:12","EW 760-1:14","EW 1200-1:18,5");
              tb1.style.top = "280px";
              tb1.style.left = "250px";
              tb2.style.top = "313px";
              tb2.style.left = "250px";
              tb3.style.top = "313px";
              tb3.style.left = "400px";
              tb4.style.top = "440px";
              tb4.style.left = "350px";
              tb5.style.top = "313px";
              tb5.style.left = "500px";
              tb5.style.display = "";
              break;
            case '1':
              this.curTOPic += "ABW_L.png"
              this.lstWeichen.push("ABW 140-1:7", "ABW 200-1:9");
              tb1.style.top = "270px";
              tb1.style.left = "300px";
              tb2.style.top = "330px";
              tb2.style.left = "230px";
              tb3.style.top = "313px";
              tb3.style.left = "360px";
              tb4.style.top = "450px";
              tb4.style.left = "350px";
              tb5.style.top = "370px";
              tb5.style.left = "550px";
              tb4.style.display = "none";
              tb5.style.display = "";
              break;
            case '2':
              this.curTOPic += "ABW_L.png"
              this.lstWeichen.push("ABW 140-1:7", "ABW 200-1:9");
              tb1.style.top = "270px";
              tb1.style.left = "300px";
              tb2.style.top = "330px";
              tb2.style.left = "230px";
              tb3.style.top = "313px";
              tb3.style.left = "360px";
              tb4.style.top = "450px";
              tb4.style.left = "350px";
              tb5.style.top = "370px";
              tb5.style.left = "550px";
              tb6.style.top = "450px";
              tb6.style.left = "550px";
              tb5.style.display = "";
              tb6.style.display = "";
              break;
            case '3':
              this.curTOPic += "EKW_Vignol.png"
              this.lstWeichen.push("EKW 100-1:6", "EKW 140-1:7", "EKW 190-1:9");
              tb1.style.top = "300px";
              tb1.style.left = "280px";
              tb2.style.top = "330px";
              tb2.style.left = "380px";
              tb3.style.top = "460px";
              tb3.style.left = "263px";
              tb4.style.top = "400px";
              tb4.style.left = "560px";
              tb5.style.top = "380px";
              tb5.style.left = "560px";
              tb4.style.display = "none";
              tb5.style.display = "";
              break;
            case '4':
              this.curTOPic += "DKW_Vignol.png"
              this.lstWeichen.push("DKW 100-1:6", "DKW 140-1:7", "DKW 190-1:9");
              tb1.style.top = "300px";
              tb1.style.left = "280px";
              tb2.style.top = "330px";
              tb2.style.left = "380px";
              tb3.style.top = "460px";
              tb3.style.left = "263px";
              tb4.style.top = "380px";
              tb4.style.left = "560px";
              tb5.style.display = "none";
              break;
            case '5':
              this.curTOPic += "Kr.png"
              this.lstWeichen.push("Regel Kr 1:2,917", "Regel Kr 1:3,429", "Regel Kr 1:4,444", "Regel Kr 1:6", "Regel Kr 1:7", "Regel Kr 1:9", "Meter Kr 1:2,917", "Meter Kr 1:3,429", "Meter Kr 1:4,444", "Meter Kr 1:6", "Meter Kr 1:7", "Meter Kr 1:9");
              tb1.style.top = "300px";
              tb1.style.left = "280px";
              tb2.style.top = "330px";
              tb2.style.left = "380px";
              tb3.style.top = "460px";
              tb3.style.left = "263px";
              tb4.style.top = "380px";
              tb4.style.left = "560px";
              tb5.style.display = "none";
              break;
            case '6':
              this.curTOPic += "EW_L.png"
              this.lstWeichen.push("EW 50-1:3,25", "EW 100-1:5", "EW 140-1:6", "EW 190-1:7", "EW 300-1:9", "EW 500-1:12", "EW 760-1:14", "EW 1200-1:18,5", "EW 50-1:6", "EW 100-1:6", "EW 100-1:7", "EW 140-1:7", "EW 190-1:9");
              tb1.style.top = "380px";
              tb1.style.left = "330px";
              tb2.style.top = "260px";
              tb2.style.left = "463px";
              tb3.style.top = "560px";
              tb3.style.left = "400px";
              tb4.style.top = "380px";
              tb4.style.left = "560px";
              tb5.style.display = "none";
              break;
            case '7':
                //this.lstWeichen.push("DKW 100-1:6", "DKW 140-1:7", "DKW 190-1:9"]);
                break;
            default:
              break;
          }
          this.tblWeichenTypen.rows.forEach(rw => {
            this.lstWeichen.push(rw["Bezeichnung"]);
          });
          this.setTOTypes(this.lstWeichen[0]);
  }});
  }


  setTOTypes(event:any) {
    this.selTOObject = event;
    var tb1 = document.getElementById('tb1') as HTMLInputElement;
    var tb2 = document.getElementById('tb2') as HTMLInputElement;
    var tb3 = document.getElementById('tb3') as HTMLInputElement;
    var tb4 = document.getElementById('tb4') as HTMLInputElement;
    var tb5 = document.getElementById('tb5') as HTMLInputElement;
    var tb6 = document.getElementById('tb6') as HTMLInputElement;

    this.apiservice
    .getTOEditSchiene(this.selTOType.toString(),event)
    .pipe()
    .subscribe((res: any) => {
      if (res) {
        if(res.rows.length > 0) {
          var rw = res.rows[0];
          tb1.value = rw.Value1;
          tb2.value = rw.Value2;
          tb3.value = rw.Value3;
          tb4.value = rw.Value4;
          tb5.value = rw.Value5;
          tb6.value = rw.Value6;

          setTimeout(() => {
            this.tb1 = tb1.value;
            this.tb2 = tb2.value;
            this.tb3 = tb3.value;
            this.tb4 = tb4.value;
            this.tb5 = tb5.value;
            this.tb6 = tb6.value;
          },200);
          return;
        }
      }
    });
    switch (event) {
      case "EW 50-1:3,25":
        tb1.value  = "15036"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = tb2.value
        tb5.value = "2211"
        break;

      case "EW 100-1:5":
        tb1.value = "19804"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = tb2.value
        tb5.value = "1942"
        break;
      case "EW 140-1:6":
        tb1.value = "23174"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = tb2.value
        tb5.value = "1905"
        break;
      case "EW 190-1:9":
        if (this.selTOType < 5) {
          tb1.value = "27006"
          tb2.value = (parseInt(tb1.value) / 2).toString()
          tb3.value = tb2.value
          tb4.value = tb2.value
          tb5.value = "1910"
        }
        else {
          tb1.value = "27138"
          tb2.value = "10523"
          tb3.value = "16615"
          tb4.value = "16615"
          tb5.value = "1838"
        }
        break;
      case "EW 150-1:6.6":
        tb1.value = "26980"
        tb2.value = "11299"
        tb3.value = "15681"
        tb4.value = "15681"
        tb5.value = "2356"
        break;
      case "EW 190-1:6.6":
        tb1.value = "30039"
        tb2.value = "14312"
        tb3.value = "15020"
        tb4.value = "15020"
        tb5.value = "2363"
        break;
      case "EW 300-1:9":
        tb1.value = "33230"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = tb2.value
        tb5.value = "1942"
        break;
      case "EW 500-1:12":
        tb1.value = "41594"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = tb2.value
        tb5.value = "1727"
        break;
      case "EW 760-1:14":
        tb1.value = "54216"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = tb2.value
        tb5.value = "1931"
        break;
      case "EW 1200-1:18,5":
        tb1.value = "64818"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = tb2.value
        tb5.value = "1748"
        break;
      case "Regel Kr 1:2,917":
        tb1.value = "13440"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "2210"
        tb5.value = "332"
        break;
      case "Regel Kr 1:3,429":
        tb1.value = "15420"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "2181"
        tb5.value = "308"
        break;
      case "Regel Kr 1:4,444":
        tb1.value = "21366"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "2359"
        tb5.value = "261"
        break;
      case "Regel Kr 1:6":
        tb1.value = "23056"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1902"
        tb5.value = "157"
        break;
      case "Regel Kr 1:7":
        tb1.value = "26448"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1875"
        tb5.value = "133"
        break;
      case "Regel Kr 1:9":
        tb1.value = "32966"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1823"
        tb5.value = "101"
        break;
      case "Meter Kr 1:2,917":
        tb1.value = "10842"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1782"
        tb5.value = "293"
        break;
      case "Meter Kr 1:3,429":
        tb1.value = "12390"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1752"
        tb5.value = "248"
        break;
      case "Meter Kr 1:4,444":
        tb1.value = "17470"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1929"
        tb5.value = "213"
        break;
      case "Meter Kr 1:6":
        tb1.value = "16344"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1348"
        tb5.value = "111"
        break;
      case "Meter Kr 1:7":
        tb1.value = "18890"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1339"
        tb5.value = "95"
        break;
      case "Meter Kr 1:9":
        tb1.value = "24292"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1343"
        tb5.value = "74"
        break;
      case "ABW 140-1:7":
        tb1.value = "19900"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = tb2.value
        tb5.value = "1407"
        tb6.value = "1407"
        break;
      case "ABW 200-1:9":
        tb1.value = "22144"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = tb2.value
        tb5.value = "1223"
        tb6.value = "1223"
        break;
      case "EKW 100-1:6": case "DKW 100-1:6":
        tb1.value = "23056"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1902"
        tb5.value = "157"
        break;
      case "EKW 140-1:7": case "DKW 140-1:7":
        tb1.value = "26448"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1875"
        tb5.value = "133"
        break;
      case "EKW 190-1:9": case "DKW 190-1:9":
        tb1.value = "32966"
        tb2.value = (parseInt(tb1.value) / 2).toString()
        tb3.value = tb2.value
        tb4.value = "1823"
        tb5.value = "101"
        break;
      case "Standard":
        tb1.value = "30072"
        tb2.value = "30072"
        tb3.value = "2200"
        break;
      default:
        break;
    }
    setTimeout(() => {
      this.tb1 = tb1.value;
      this.tb2 = tb2.value;
      this.tb3 = tb3.value;
      this.tb4 = tb4.value;
      this.tb5 = tb5.value;
      this.tb6 = tb6.value;
    },200);
  }

  switchDir() {
    this.getTOData(this.selTOType);
  }

  startPlaceTOSymbol(event:any){
    this.tb1 = (document.getElementById('tb1') as HTMLInputElement).value;
    this.tb2 = (document.getElementById('tb2') as HTMLInputElement).value;
    this.tb3 = (document.getElementById('tb3') as HTMLInputElement).value;
    this.tb4 = (document.getElementById('tb4') as HTMLInputElement).value;
    this.tb5 = (document.getElementById('tb5') as HTMLInputElement).value;
    this.tb6 = (document.getElementById('tb6') as HTMLInputElement).value;

    this.action.set("placeTO");
    this.selTOType = (this.selTOType * 2) + 1;
    if(this.modalTOEditor.value.richtung == "1") this.selTOType += 1
    this.map.getViewport().style.cursor = "crosshair";
    if(this.prevTarget === event.currentTarget) {
      this.action.set("");
      this.prevTarget = undefined;
      event.currentTarget.style.backgroundColor = "#F0F0F0";
      this.map.getViewport().style.cursor = "grab";
      return;
    }
    if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0"
    event.currentTarget.style.backgroundColor = "#9E9E9E"
    this.prevTarget = event.currentTarget;
    this.isTurnoutEditorVisible = false;
  }

  modifySelected(event:any){
    if(this.action() != "modify") {
      this.action.set("modify");
      this.map.addInteraction(this.modify);
      this.map.removeInteraction(this.hoverSelect);

      if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
      event.currentTarget.style.backgroundColor = "#9E9E9E";
      this.prevTarget = event.currentTarget;
    } else {
      this.action.set("");
      this.modPointsLayer.getSource().clear();
      this.map.removeInteraction(this.modify);
      this.map.addInteraction(this.hoverSelect);
      this.prevTarget.style.backgroundColor = "#F0F0F0";
    }

  }

  getPos(event:any){
    if(this.action() != "getPos") {
      this.action.set("getPos");

      if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
      event.currentTarget.style.backgroundColor = "#9E9E9E";
      this.prevTarget = event.currentTarget;
    } else {
      this.action.set("");
      this.prevTarget.style.backgroundColor = "#F0F0F0";
    }

  }

  setMeasure(event:any){
    if(this.action() != "measure") {
      this.action.set("measure");
      this.measure.SetSecondPoint = false;
      if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
      event.currentTarget.style.backgroundColor = "#9E9E9E";
      this.prevTarget = event.currentTarget;

      this.map.removeInteraction(this.draw);
      this.map.removeInteraction(this.snap);
      this.addInteraction("LineString");
    } else {
      this.action.set("");
      this.prevTarget.style.backgroundColor = "#F0F0F0";
    }

  }

  setMoveAll(event:any){
    if(this.action() != "moveAll") {
      this.action.set("moveAll");
      this.measure.SetSecondPoint = false;
      if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
      event.currentTarget.style.backgroundColor = "#9E9E9E";
      this.prevTarget = event.currentTarget;

      this.map.removeInteraction(this.draw);
      this.map.removeInteraction(this.snap);
      this.addInteraction("LineString");
    } else {
      this.action.set("");
      this.prevTarget.style.backgroundColor = "#F0F0F0";
    }

  }

  cutSelected(event:any) {
    if(this.activeElement) {
      this.map.getViewport().style.cursor = "crosshair";
      this.action.set("cut");
    }

  }
  cutSelectedAtPos(event:any) {
    if(this.activeElement) {
      this.isPositionModalVisible = true;
      this.featLen = geoDesLength(this.activeElement.getGeometry()).toFixed(2);
      this.action.set("cut");
    }
  }
  separateSelected(event:any) {
    if(this.activeElement) {
      this.map.getViewport().style.cursor = "crosshair";
      this.action.set("separate");
    }

  }
  separateSelectedAtPos(event:any) {
    if(this.activeElement) {
      this.isPositionModalVisible = true;
      this.featLen = geoDesLength(this.activeElement.getGeometry()).toFixed(2);
      this.action.set("separate");
    }
  }


  mergeSelected(event:any) {

    if(this.activeElement) {
      if(this.action() != "merge") {
        this.action.set("merge");

        if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
        event.currentTarget.style.backgroundColor = "#9E9E9E";
        this.prevTarget = event.currentTarget;
      } else {
        this.action.set("");
        this.prevTarget.style.backgroundColor = "#F0F0F0";
      }
    }
  }

  moveToPos() {
    this.action.set("");
    let info = document.getElementById('GeoPosMove') as HTMLInputElement;
    var coord = info.value.split(",");

    var pnt = new Point(toLonLat([parseFloat(coord[0]),parseFloat(coord[1])]));
    this.map.getView().fit(
      pnt, {padding: [170, 50, 30, 150], minResolution: 40}
    );
    this.isGeoPosModalVisible = false;
  }

  setRotateLine(event:any) {
    if(this.activeElement) {
      this.activeElement = this.rotateLine(this.activeElement);
      this.activeElement.values_.isEdited = true;
    }
  }

  sortLine(event:any) {

    if(this.activeElement) {
      if(this.action() != "sort") {
        this.action.set("sort");

        if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
        event.currentTarget.style.backgroundColor = "#9E9E9E";
        this.prevTarget = event.currentTarget;
      } else {
        this.action.set("");
        this.prevTarget.style.backgroundColor = "#F0F0F0";
      }
    }
  }

  findNearestSegment(coords: Coordinate[], point: Coordinate) : any {
    let coord : Coordinate;
    let index = 0;
    let lastIndex = 0;
    var dist = 999999999;
    coords.forEach(element => {
      var line = new LineString([point, element]);
      if (geoDesLength(line) < dist) {
        coord = element;
        dist = geoDesLength(line);
        lastIndex = index;
      }
      index +=1;
    });
    return {coord : coord, index: lastIndex};
  }

  cutLine(val:any) {
    let info = val == -1 ? (document.getElementById('PositionCut') as HTMLInputElement).value : val;
    var feat = this.activeElement;
    var lastPos = 1;
    var coords = this.activeElement.getGeometry().getCoordinates();
    var coordPoint = feat.getGeometry().getCoordinateAt(parseFloat(info) / geoDesLength(feat.getGeometry()));
    if(parseFloat(info) > geoDesLength(feat.getGeometry()) || parseFloat(info) < 0) return;
    this.len = 0;

    feat.getGeometry().forEachSegment((a, b) => {
      const segment = new LineString([a, b]);
      this.len += Math.round(geoDesLength(segment) * 100) / 100;
      if (this.len > parseFloat(info)) {
        return;
      }
      lastPos += 1;
    });

    var tmp = feat.clone();
    this.map.getViewport().style.cursor = "grab";
    var splicedCoors = coords.slice(0,lastPos);
    splicedCoors.push(coordPoint);
    feat.getGeometry().setCoordinates(splicedCoors);
    feat.values_.isEdited = true;
    this.enableSave = true;

    if (this.action() == "separate") {
      //Hard Copy von dem aktiven element, erste Koordinate muss schnittpunkt sein, dann umwandeln in EPSG 4326 (von m in °)
      coords = tmp.getGeometry().getCoordinates();
      coords = coords.slice(lastPos,coords.length);
      coords.splice(0,0,coordPoint);
      tmp.getGeometry().setCoordinates(coords);
      coords = tmp.getGeometry().transform('EPSG:3857', 'EPSG:4326').getCoordinates();

      let c: any = {};
      c.koordinaten = coords;
      c.typid = 1;
      this.apiservice
        .setNewFeature(c)
        .pipe()
        .subscribe((res: any) => {
          let src = this.topojsonLayer.getSource();
          let features = src.getFormat().readFeature(res, {
            featureProjection: "EPSG:3857",
          });
          this.tempfeaturesList.push({ type: 'new_Line', feature: res });
          src.addFeature(features);
        });
    }
    this.action.set("");
    this.isPositionModalVisible = false;
    return feat.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326').getCoordinates()[lastPos];
  }

  objTypChanged(val:number) {
    this.curObjTyp = val;
    this.apiservice
      .getKarteAnlagen(this.curObjTyp)
      .pipe()
      .subscribe((res: any) => {
        if (res) {
          if (this.tblAnl?.columns?.length)
          this.tblAnl.rows = res.rows;
          else this.tblAnl = res;
        }
      });

  }

  TOEditorCreateTO(event:any){
    if(this.modalTOEditor.value.BezPosition) {
      let c: any = {};
      c.typID = this.selTOType;
      c.Bezeichnung = this.modalTOEditor.value.BezPosition;
      c.tb1 = (document.getElementById('tb1') as HTMLInputElement).value //Verwende aktuell direkt aus dem Dokument weil Formcontrol einfach nicht immer die Daten lädt, wieso auch immer.
      c.tb2 = (document.getElementById('tb2') as HTMLInputElement).value
      c.tb3 = (document.getElementById('tb3') as HTMLInputElement).value
      c.tb4 = (document.getElementById('tb4') as HTMLInputElement).value
      c.tb5 = (document.getElementById('tb5') as HTMLInputElement).value
      c.tb6 = (document.getElementById('tb6') as HTMLInputElement).value

      this.apiservice
        .newTOSymbol(c)
        .pipe()
        .subscribe((res: any) => {
          this.getTOData(this.selTOType);
          this.setTOTypes(this.lstWeichen[0]);
        });
    }

  }
  TOEditorDeleteTO(event: any) {
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie das ausgewählte Objekt löschen wollen?"))) {
      let c: any = {};
      c.typID = this.selTOType;
      c.Bezeichnung = this.selTOObject;
      this.apiservice
        .deleteTOSymbol(c)
        .pipe()
        .subscribe((res: any) => {
          this.getTOData(this.selTOType);
          this.setTOTypes(this.lstWeichen[0]);
        });
    }
  }
  TOEditorEditTO(event:any){
    let c: any = {};
    c.typID = this.selTOType;
    c.BezeichnungOld = this.selTOObject;
    c.Bezeichnung = this.modalTOEditor.value.BezPosition == "" ? this.selTOObject : this.modalTOEditor.value.BezPosition;
    c.tb1 = (document.getElementById('tb1') as HTMLInputElement).value
    c.tb2 = (document.getElementById('tb2') as HTMLInputElement).value
    c.tb3 = (document.getElementById('tb3') as HTMLInputElement).value
    c.tb4 = (document.getElementById('tb4') as HTMLInputElement).value
    c.tb5 = (document.getElementById('tb5') as HTMLInputElement).value
    c.tb6 = (document.getElementById('tb6') as HTMLInputElement).value
    this.apiservice
      .editTOSymbol(c)
      .pipe()
      .subscribe((res: any) => {
        this.getTOData(this.selTOType);
        this.setTOTypes(this.lstWeichen[0]);
      });
  }

  createMapSet() {
    let c: any = {};
    c.ID = this.isnewMapSet ? "-99" : this.curMapSet
    c.Name = (document.getElementById('SetName') as HTMLInputElement).value;
    this.apiservice
      .saveMapSet(c)
      .pipe()
      .subscribe((res: any) => {
        this.tblKartenset.rows = res.rows;
        setTimeout(() => {
          (document.getElementById('selMapSet') as HTMLInputElement).value = c.ID;
        }, 100);
        this.isnewMapSet = false;
        this.isMapSetNameVisible = false;
      });
  }
  deleteMapSet() {
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie das ausgewählte Kartenset löschen wollen? Damit können Sie nicht mehr auf dessen Elemente zugreifen."))) {
    this.apiservice
      .deleteMapSet(this.curMapSet)
      .pipe()
      .subscribe((res: any) => {
        this.tblKartenset.rows = res.rows;
        setTimeout(() => {
          (document.getElementById('selMapSet') as HTMLInputElement).value = "1";
          this.curMapSet = "1";
          this.reloadTopoJson();
        }, 100);
        this.isnewMapSet = false;
        this.isMapSetNameVisible = false;
        this.toastr.success(
          this.mrTranslate.transform("Kartenset wurde gelöscht!")
        );
      });
    }
  }

  onFileOSMChange(event:any) {
    let files: File[] = event.target.files;
    let fileReader = new FileReader();
    this.tempfeaturesList = [];
    fileReader.onload = (e: any) => {
          this.spinner.enable();
          const formatOSM = new OSMXML();
          const features:any = formatOSM.readFeatures(fileReader.result);
          let src = this.topojsonLayer.getSource();
          //src.addFeatures(features);
          features.forEach(feat => {
            if(feat.getProperties().railway) {
              feat.getGeometry().transform(this.curEPSG,'EPSG:3857').getCoordinates();
              feat.setProperties({"layername": "objekttyp.0"})
              if(feat.getGeometry().getType() == "Point") {
                feat.setProperties({"PointID": "0"})
              }
              this.tempfeaturesList.push({ type: 'import_Line', feature: feat, geom: feat.getGeometry().clone().transform('EPSG:3857','EPSG:4326' ).getCoordinates() });
              src.addFeature(feat);
            }
          });
          this.enableSave = true;
        this.topojsonLayer.setStyle(this.getLayerStyles());
        this.toastr.success(
          this.mrTranslate.transform("Daten importiert")
        );
        this.spinner.disable();
      };
      fileReader.readAsText(files[0]);
  }

  onFileSHPChange(event:any) {
    let files: File[] = event.target.files;
    let fileReader = new FileReader();
    let featureList = [];
    let stammDatenMap = new Map();

    var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
            jsZip.loadAsync(files[0]).then((zip) => {
              Object.keys(zip.files).forEach((filename) => {
                if(filename.includes(".prj")) {
                  jsZip.remove(filename);
                }
              });
              zip.generateAsync({type: "blob"}).then((z) => {
                fileReader.readAsArrayBuffer(z)
              });
            });

    fileReader.onload = (e: any) => {
      this.spinner.enable();
      var dom = document.getElementById('fileInputSHP') as HTMLInputElement
      dom.value = "";
      this.apiservice
        .getStammDatenBez()
        .pipe()
        .subscribe((res: any) => {
          stammDatenMap = new Map(Object.entries(res));
          const geojson = shp(fileReader.result).then(e => {
            e.forEach(fCol => {
              featureList = [];
              let src = this.topojsonLayer.getSource();
              const features :any = new OlTopoJSON().readFeatures(fCol);
              features.forEach(feat => {
                feat.getGeometry().transform(this.curEPSG,'EPSG:3857').getCoordinates();
                feat.setProperties({"layername": "objekttyp.0"})
                if(feat.getGeometry().getType() == "Point") {
                  feat.setProperties({"PointID": "0"})
                }
                if(feat.getProperties().TRAM && stammDatenMap.has(feat.getProperties().TRAM)) {
                  feat.setProperties({"OSTAMMID": stammDatenMap.get(feat.getProperties().TRAM)})
                }
                this.tempfeaturesList.push({ type: 'import_Line', feature: feat, geom: feat.getGeometry().clone().transform('EPSG:3857','EPSG:4326' ).getCoordinates() });
              });
              src.addFeatures(features);
              this.enableSave = true;
              this.topojsonLayer.setStyle(this.getLayerStyles());
              this.toastr.success(
                this.mrTranslate.transform("Daten importiert")
              );
              this.spinner.disable();
            });
          });
        })
      };

  }

  EPSGChanged(value: string) {
    this.curEPSG = value;
  }

  editFeature() {
    let c: any = {};
    var prop = this.activeElement.getProperties();
    c.ID = prop.ID;
    c.TypID = this.selTbl.OTYPID;
    c.StammID = this.selTbl.OSTAMMID;
    c.koordinaten = this.activeElement.getGeometry().transform('EPSG:3857', 'EPSG:4326').getCoordinates();
    c.Name = prop.PointID || "-99";
    if(prop.InternalID) c.InternalID = prop.InternalID;
    c.value = "";
    prop.angle ? c.Winkel = prop.angle : c.Winkel = 0;
    c.Messwerte = "";
    this.apiservice
        .editFeature(c)
        .pipe()
        .subscribe((res: any) => {
          this.activeElement.values_.OTYPID = this.selTbl.OTYPID;
          this.activeElement.values_.OSTAMMID = this.selTbl.OSTAMMID;
          this.activeElement.getGeometry().transform('EPSG:4326', 'EPSG:3857').getCoordinates();
          if(c.ID == -1) {
            this.tempfeaturesList.push({type:'edit_new_Feature', feature: res});
          } else {
            //this.tempfeaturesList.push({type:'edit_Feature', feature: this.activeElement.getProperties(), geom: this.activeElement.getGeometry().getCoordinates()});
            this.activeElement.values_.isEdited = true;
            this.enableSave = true;
          }

          this.toastr.success(
            this.mrTranslate.transform("Objekt bearbeitet.")
          );
          this.isObjLinkVisible = false;
        });
  }
  deleteSelected() {
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie das ausgewählte Objekt löschen wollen?"))) {
      var id = this.activeElement.getProperties().ID;
      if(this.activeElement.getProperties().InternalID) id = this.activeElement.getProperties().InternalID + "i";
      if(this.deleteFeature(id, this.activeElement) == "OK")
        this.toastr.success(
          this.mrTranslate.transform("Objekt gelöscht.")
        );
    }
  }
  deleteFeature(id: string, feature: any) {
    this.apiservice
        .deleteFeature(id)
        .pipe()
        .subscribe((res: any) => {
          let src = this.topojsonLayer.getSource();
          let features = src.getFormat().readFeature(res, {
            featureProjection: "EPSG:3857",
          });
          //Wenn das gelöschte ein neu erstelltes Feature aus der temp-Liste ist, aus der temp-Liste entfernen
          this.hasIntIDFound = false;
          for (let index = 0; index < this.tempfeaturesList.length; index++) {
            const el = this.tempfeaturesList[index];
            var feat = el.feature.properties;
            if(feat.InternalID && features.getProperties().InternalID && feat.InternalID == features.getProperties().InternalID) {
              this.hasIntIDFound = true;
              this.tempfeaturesList.splice(index,1);
            }
          }
          if (!this.hasIntIDFound) this.tempfeaturesList.push({ type: 'delete_Line', feature: res });
          src.removeFeature(feature);
          return "OK";
        });
        return "";
  }

  saveFeatures() {
    let v:any = {};
    this.spinner.enable();
    this.topojsonLayer.getSource().getFeatures().forEach(feat => {
      if(feat.getProperties().isEdited) {
        this.tempfeaturesList.push({ type: 'edit_Feature', feature: feat.getProperties(), geom: feat.getGeometry().transform('EPSG:3857', 'EPSG:4326').getCoordinates()})
        feat.getGeometry().transform('EPSG:4326', 'EPSG:3857').getCoordinates()
      }
    });
    v.fileID = this.curMapSet;
    v.features = this.tempfeaturesList;

    this.apiservice
      .saveFeatures(v)
      .pipe()
      .subscribe((res: any) => {
        this.spinner.disable();
        if (res) {
          this.tempfeaturesList = [];
        }
        this.reloadTopoJson()
      });
      this.reset();
  }

  rotateSymbol(event) {
    if (this.activeElement && this.activeElement.getProperties().PointID) {
      if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
      event.currentTarget.style.backgroundColor = "#9E9E9E";
      this.prevTarget = event.currentTarget;

      this.action.set("rotate");
    }
  }
  moveSymbol(event) {
    if (this.activeElement && this.activeElement.getProperties().PointID) {
      if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
      event.currentTarget.style.backgroundColor = "#9E9E9E";
      this.prevTarget = event.currentTarget;

      this.action.set("move");
    }
  }



  GetBearing(sP, eP)
  {
    var dx1 = sP[0] - eP[0];
    var dy1 = sP[1] - eP[1];
    var dx0 = dx1 - sP[0];
    var dy0 = dy1 - sP[1];
    var a0 = Math.atan2(dy0, dx0);
    var a1 = Math.atan2(dy1, dx1);
    var angle = a1 - a0;
    //angle *= 180 / Math.PI;
    return angle;
  }

  startEdit(){
    this.map.interactions.array_ = this.editInteractions;
  }

  undoDraw() {
    this.draw.removeLastPoint();
  };

  reloadTopoJson() {
    this.spinner.enable();
    this.reset();
    this.persistanceService.set("karteFileID", this.curMapSet);
    this.apiservice
      .refreshTopoJson(this.curMapSet)
      .pipe()
      .subscribe((res: any) => {
        if (res && res.message && res.message == "success") {
          this.topojsonLayer.getSource().refresh();
          this.modPointsLayer.getSource().clear();
          this.spinner.disable();
          this.toastr.success(
            this.mrTranslate.transform("Daten aktualisiert.")
          );
        } else {
          this.spinner.disable();
        }
      });
    this.overLayers = duplicate(this.overLayersOriginal);
    this.getStrValues(undefined);
  }
  reset() {
    this.action.set("");
    this.enableSave = false;
    this.tempfeaturesList = [];

    if (this.prevTarget) this.prevTarget.style.backgroundColor = "#F0F0F0";
  }

  pathJoin(parts, sep) {
    var separator = sep || "/";
    var replace = new RegExp(separator + "{1,}", "g");
    return parts.join(separator).replace(replace, separator);
  }

  rotateLine(feat:Feature<LineString>) : Feature<LineString> {
    feat.getGeometry().setCoordinates(feat.getGeometry().getCoordinates().reverse())
    return feat;
  }

  hoverStyleFunction() {
    let dark =
      this.baseLayer[1] == "cartoDbDark" ||
      this.baseLayer[1] == "cartoDbDarkNoLabels" ||
      this.baseLayer[1] == "mapboxDark";
    let baseColor = dark ? "rgba(170,170,170,0.6)" : "rgba(0,0,0,0.6)";

    let valmap = function (x, in_min, in_max, out_min, out_max) {
      return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    };

    return (feature, resolution) => {
      let feat = feature as any;
      let geom = feat.getGeometry();
      let prop = feat.getProperties();
      if (
        geom.getType() == "LineString" ||
        geom.getType() == "MultiLineString"
      ) {
        if (prop.layername == "objekttyp.0") {
          this.elementStyles.line.getStroke().setColor(this.hoverEmptyColor);
          this.elementStyles.line.getStroke().setLineDash([6, 6]);
          this.elementStyles.line.setZIndex(0);
        } else {
          this.elementStyles.line.getStroke().setColor(this.hoverColor);
          this.elementStyles.line.getStroke().setLineDash([6, 6]);
          this.elementStyles.line.setZIndex(99);


          if (this.showObjText && prop.Name != null)  {
              this.elementStyles.line.setText(
                new OlText ({
                text: prop.Name ,
                font: "Arial bold",
                //offsetY: 12,
                //offsetX: 12,
                textAlign: 'center',
                justify: 'center',
                backgroundFill: new OlFill({color: "lightgreen"}),
                scale: 1.2
              })
            );
          }else {
            this.elementStyles.line.setText(null);
          }
        }

        if(this.action() == "modify" && this.modPointsLayer.getSource().getFeatures().length == 0) {
          geom.getCoordinates().forEach(element => {
            var pointFeature = new Feature({
              geometry: new Point(element),
              name: 'XYZ'
              });
              let src = this.modPointsLayer.getSource();
              src.addFeature(pointFeature);
          });
        }

        this.modPointsLayer.setStyle(this.modPointStyleFunction());

        let styles = [this.elementStyles.line];
        let map = this.map;
        let info = document.getElementById('info');
        if (this.showMangelBem && prop.Bemerkung) {

          info.style.visibility = 'visible';
          info.innerHTML = prop.Bemerkung
          let coordinates = prop.geometry.getCoordinates();
          let end = coordinates[Math.floor(coordinates.length / 2)];
          let endp = this.map.getPixelFromCoordinate(end);
          info.style.left = endp[0]+'px';
          info.style.top = endp[1]+'px';
        }
        else {
          info.style.visibility = 'hidden';
          info.innerText = null;
        }


        // Arrow heads
        if (
          this.objtypePipe.transform(prop.OTYPID) == "line" &&
          geom.getType() != "MultiLineString"
        ) {
          let tmpFeat = geom as LineString;
          let coordinates = tmpFeat.getCoordinates();
          let coordlen = coordinates.length;
          let end = coordinates[coordlen - 1];
          let beforeEnd = coordinates[coordlen - 2];
          let dx = end[0] - beforeEnd[0];
          let dy = end[1] - beforeEnd[1];
          let angle = Math.atan2(dy, dx) + Math.PI / 2;
          let endp = map.getPixelFromCoordinate(end);

          // 17 - 10
          // 12 - 5#
          const val = valmap(map.getView().getZoom(), 10, 20, 4, 10);

          let coords = [
            [
              [endp[0] + val, endp[1]],
              [endp[0], endp[1] + val * 1.8],
              [endp[0] - val, endp[1]],
              [endp[0] + val, endp[1]],
            ],
          ];
          coords[0] = coords[0].map((val) => {
            return map.getCoordinateFromPixel(val);
          });

          let polygon = new OlPolygon(coords);
          polygon.rotate(angle, end);
          styles.push(
            new OlStyle({
              zIndex: 99,
              geometry: polygon,
              fill: new OlFill({
                color: this.hoverColor,
              }),
            })
          );
        }

        return styles;

      } else if (geom.getType() == "Point" && prop.OSTAMMID > 0 && !prop.PointID) {
        this.elementStyles.point.setImage(
          new CircleStyle({
            radius: 4,
            stroke: new OlStroke({
              color: this.hoverColor,
              width: 2,
            }),
            fill: new OlFill({ color: this.hoverColor }),
          })
        );
        return this.elementStyles.point;


      } else if (geom.getType() == "Point" && prop.PointID) {
        // this.elementStyles.point.setImage(
        //   dark
        //     ? this.elementStyles.hoverCircleImage.dark
        //     : this.elementStyles.hoverCircleImage.light
        // );
        let smallIcon = prop.PointID == "5" || prop.PointID == "23" || (prop.PointID >= "30" && prop.PointID <= "41")
            let imagestl = new Icon({
              src: "assets/icons/GM_Typ_" + prop.PointID + ".svg",
              scale: smallIcon? 0.2 / resolution : 0.3 / resolution,
              rotation: prop.angle,
              anchor: smallIcon? [0.5,0.5]: [0.5,1.0],
              color: this.hoverColor
            })
        this.elementStyles.point.setImage(imagestl);
        return this.elementStyles.point;
      }
    };
  }
  modPointStyleFunction() {
    return (feature, resolution) =>{
      this.elementStyles.point.setImage(
        new CircleStyle({
          radius: 4,
          stroke: new OlStroke({
            color: "rgba(0,0,0,0.6)",
            width: 2,
          }),
          fill: new OlFill({ color: 'blue' }),
        })
      );
      return this.elementStyles.point;
    }
  }

  showPos(feature:any) {
    let position = document.getElementById('position');
    var isLineString: boolean = (this.activeElement.getGeometry().getType() == "LineString" && !this.activeElement.getProperties().PointID);
    if (this.mousepos && this.activeElement && feature == this.clickSelect.getFeatures().item(0) && this.action() != "modify") {
      this.len = 0;
      this.lenSphere = 0;
      var closePnt = feature.getGeometry().getClosestPoint(this.mousepos);

      if (isLineString) {
        this.len = feature.getGeometry().forEachSegment((a, b) => {
          const segment = new LineString([a, b]);
          const closeSeg = segment.getClosestPoint(this.mousepos);
          if (closeSeg[0] == closePnt[0] && closeSeg[1] == closePnt[1]) {
            const segToMouse = new LineString([a, closeSeg]);
            this.len += geoDesLength(segToMouse);
            return this.len;
          }
          this.len += Math.round(geoDesLength(segment) * 100) / 100;
        });
        if (!this.len || this.len > geoDesLength(feature.getGeometry())) return;
      }
      position.style.visibility = 'visible';
      position.style.width = "90px";
      position.style.lineHeight = "14px";

      if (isLineString) {
        position.innerHTML = this.len.toFixed(2) + " m <br><font size = 1> " + geoDesLength(feature.getGeometry()).toFixed(2) + " m</font>";
        if (this.altPressed) 
          position.innerHTML += "<br><font size = 1> " + feature.getProperties().layername + "<br>ID: " + feature.getProperties().ID + "<br> Stamm:" + feature.getProperties().OSTAMMID + "<br></font>";
        
      } else {
        if (this.altPressed)
          position.innerHTML = "<font size = 1>" + this.activeElement.getProperties().layername + "<br>ID: " + this.activeElement.getProperties().ID + 
          "<br>Stamm:" + this.activeElement.getProperties().OSTAMMID + "<br>PointID:" + this.activeElement.getProperties().PointID + "</font>";
        else
          position.style.visibility = 'hidden';
      }
      let pendp = this.map.getPixelFromCoordinate(closePnt);
      position.style.left = pendp[0] - 6 + 'px';
      position.style.top = pendp[1] + 7 + 'px';
    }
    else {
      position.style.visibility = 'hidden';
      position.innerText = null;
    }
  }
  ngOnDestroy(): void {
    if (this.map) {
      this.basicStore.setComponentStore(
        "karte",
        {
          view: this.map.getView(),
          baseLayer: this.baseLayer,
          overLayer: this.overLayIds,
          activeElement: this.activeElement,
          ostammid: this.ostammid,
          showdetailview: this.showdetailview,
          // * wird gespeichert, aber restoreMapState wird nirgends aufgerufen
          hidedetailview: this.hidedetailview,
          sidenavenlarged: this.sidenavenlarged,
          progressbarenlarged: this.progressbarenlarged,
          split50: this.split50,
        },
        false
      );

      this.map.setTarget(undefined);
    }

    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.authService
      .get$token()
      .pipe()
      .subscribe((token) => {
        if (token && token.rights) {
          if (!getTokenRight(token.rights.view, "AllowMAP"))
            this.router.navigateByUrl("/anlagen");
        }
      });

      this.apiservice
      .getObjekttypen()
      .pipe()
      .subscribe((val: any) => {
        this.tblObjektTypen = val;
      });


        this.positionStyle = new OlStyle({
          image: new CircleStyle({
            radius: 6,

            fill: new Fill({
              color: '#3399CC',
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 2,
            }),
          }),
        }),

      this.posLayer = new OlVectorLayer({
        map: this.map,
        source: new OlVectorSource({
          features: [this.accuracyFeature, this.positionFeature],
        }),
      });



  }

  selectFeature(feature, map?, cb?) {
    this.modPointsLayer.getSource().clear();
    this.clickSelect.getFeatures().clear();
    this.clickSelect.dispatchEvent({
      type: "select",
      selected: [feature],
    });
  }

  initMap(apikeys: any) {
    const storeddata = this.basicStore.getComponentStore("karte");
    const view = storeddata?.view;

    //PROJ4 erlaubt mehrere EPSG-Definitionen, OL nur die standards 3857 (Welt in m) und 4326 (Welt in °). Daher in PROJ4 alles laden und mit OL verknüpfen.
    const proj4x = (proj4 as any).default
    proj4x.defs("EPSG:2146","+proj=tmerc +lat_0=0 +lon_0=-76.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:2056","+proj=somerc +lat_0=46.9524055555556 +lon_0=7.43958333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:21781","+proj=somerc +lat_0=46.9524055555556 +lon_0=7.43958333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:25831","+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:25832","+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:25833","+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:31466","+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +ellps=bessel +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:31467","+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +ellps=bessel +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:31468","+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +ellps=bessel +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:31469","+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +ellps=bessel +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:31370","+proj=lcc +lat_0=90 +lon_0=4.36748666666667 +lat_1=51.1666672333333 +lat_2=49.8333339 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,-0.3366,0.457,-1.8422,-1.2747 +units=m +no_defs +type=crs");
    proj4x.defs("EPSG:32633","+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs +type=crs");
    register(proj4x);

    this.apiservice
    .getMapSettings()
    .pipe()
    .subscribe((res: any) => {
      if(res) {
        this.tblKartenset = res.mapSet;
      }

    });

    addEventListener("keyup", (e)=>{
      this.altPressed = false;
      this.shiftPressed = false;
    });

    addEventListener("keydown", (e)=>{
      if(e.altKey) this.altPressed = true;
      if(e.shiftKey) this.shiftPressed = true;
      if(e.ctrlKey && e.key=="z" && this.action() == "draw") this.draw.removeLastPoint();
      if(e.key == "Escape") {
        if(this.action() == "draw") {
          this.draw.abortDrawing();
        }
        if(this.action() == "modify") {
          this.modPointsLayer.getSource().clear();
          this.modPointsLayer.getSource().refresh();
        }
        if(this.action() == "drawarc") {
          this.markers = [];
          let src = this.topojsonLayer.getSource();
          src.removeFeature(this.drawarcFeature);
          this.drawarcFeature = undefined;
        }
        this.action.set("");
        this.showdetailview = false;
        this.ostammid = undefined;
        this.activeElement = undefined;
        this.modPointsLayer.getSource().clear();
        this.clickSelect.getFeatures().clear();
        this.hoverSelect.getFeatures().clear();
      }
      if(e.key == "Enter") {
        if(this.action() == "draw") {
          this.draw.finishDrawing();
        }
      }
      if(e.key == "Delete" && this.activeElement) {
        this.deleteSelected();
      }
    });
    addEventListener("keyup", (e)=>{
      if(e.altKey) this.altPressed = false;
    });

    //this.persistanceService.set("karte", { "0": this.baseLayer[0], "1": this.baseLayer[1] });
    if (storeddata == undefined) {
      let karte = this.persistanceService.get("karte");


      if (karte != null){
        this.baseLayer.push(karte.p);
        this.baseLayer.push(karte.m);
      }

    }
    let karteFarbe = this.persistanceService.get("karteFarbe");
    let karteFileID = this.persistanceService.get("karteFileID");
    if (karteFarbe != null) {
      this.baseColor = karteFarbe.bC;
      this.hoverColor = karteFarbe.hC;
      this.hoverEmptyColor = karteFarbe.hEC;
    }
    if (karteFarbe != null) {
      this.curMapSet = karteFileID;
    } else {
      this.curMapSet = "1";
    }

    this.showdetailview = storeddata?.showdetailview ?? false;
    this.hidedetailview = storeddata?.hidedetailview ?? false;
    this.ostammid =
      this.showdetailview && storeddata?.ostammid
        ? storeddata.ostammid
        : undefined;

    if (apikeys.bing) {
      let bingkeys = Object.keys(this.tileLayers.bing);
      for (const bk of bingkeys) {
        if (this.tileLayers.bing[bk].source) {
          this.tileLayers.bing[bk].source.key = apikeys.bing;
          this.tileLayers.bing[bk] = new OlTileLayer({
            preload: Infinity,
            source: new OlBingMaps(this.tileLayers.bing[bk].source),
          });
        }
      }
    }

    if (apikeys.mapbox) {
      let mapboxkeys = Object.keys(this.tileLayers.mapbox);
      for (const mpk of mapboxkeys) {
        let newmapboxURL = this.tileLayers.mapbox[mpk]
          .getSource()
          .getUrls()[0]
          .replace("$$apikey$$", apikeys.mapbox);
        this.tileLayers.mapbox[mpk].getSource().setUrl(newmapboxURL);
      }
    }

    this.layers = [];
    Object.keys(this.tileLayers).forEach((catname) => {
      Object.keys(this.tileLayers[catname]).forEach((layername) => {
        try {
          this.layers.push(this.tileLayers[catname][layername]);
          this.tileLayers[catname][layername].setVisible(false);
        } catch  {}

      });
    });

    this.layers.push(this.topojsonLayer);
    this.layers.push(this.modPointsLayer);
    this.layers.push(this.gleisMessLayer);

    this.clickSelect = new OlSelect({
      condition: singleClick,
      multi: false,
      style: this.hoverStyleFunction(),
    });
    this.hoverSelect = new OlSelect({
      condition: pointerMove,
      multi: false,
      style: this.hoverStyleFunction(),
    });
    this.modify = new Modify({
      features: this.clickSelect.getFeatures(),
      style: this.hoverStyleFunction(),
      insertVertexCondition: () => {
        // prevent new vertices to be added to the polygons
        if(!this.shiftPressed) return false; else return true;
      },
    });
    this.modify.on("modifyend", (e) => {
      
      this.modPointsLayer.getSource().clear();
      this.shiftPressed = false;
      e.features.forEach(feat => {
        feat.getGeometry().getCoordinates().forEach(element => {
          var pointFeature = new Feature({
            geometry: new Point(element),
            name: 'XYZ'
            });
            let src = this.modPointsLayer.getSource();
            src.addFeature(pointFeature);
        });

        feat.values_.isEdited = true;
        this.enableSave = true;
      });
    })

    this.map = new OlMap({
      target: "map",
      pixelRatio: 2,
      //loadTilesWhileInteracting: true,
      maxTilesLoading: 20,
      interactions: defaultInt({
        altShiftDragRotate: true,
        onFocusOnly: false,
        //constrainResolution: false,
        doubleClickZoom: true,
        keyboard: true,
        mouseWheelZoom: true,
        shiftDragZoom: true,
        dragPan: true,
        pinchRotate: false,
        pinchZoom: true,
      }),
      layers: this.layers,
      view: new OlView({
        center: view ? view.getCenter() : fromLonLat([6.747443, 49.981018]),
        maxZoom: 23,
        zoom: view ? view.getZoom() : 6,
      }),
      controls: defaults({ attribution: false, rotate: false }).extend([
        new OlAttribution({
          collapsible: true,
          collapsed: true,
        }),
        new OlFullScreen({
          source: "content-area",
        }),
      ]),
    });

    this.map.on("postcompose", (e) => {
      this.mapready$.next(true);
    });

    this.map.on('pointermove', e => {
      this.mousepos = e.coordinate;
      if((!this.activeElement && this.action() != "drawarc") || this.action() == "draw") {
        let position = document.getElementById('position');
        position.style.visibility = 'hidden';
        position.innerText = null;
        return;
      }
      if(this.action() == "drawarc") {
        this.activeElement = this.drawarcFeature;
        if (this.markers.length == 3)
        {
            this.markers[2] = this.mousepos;
            let L  = new LineString(this.GetPointsOfArc(this.markers));
            this.dlgRadius = (getLength(L) / 2).toFixed(2) + " m";
            this.drawarcFeature.setGeometry(L);
            let position = document.getElementById('position');
            position.style.visibility = 'visible';
            position.style.width = "90px";
            position.style.lineHeight = "14px";
            position.innerHTML = this.dlgRadius;
            var closePnt = this.activeElement.getGeometry().getClosestPoint(this.mousepos);
            let pendp = this.map.getPixelFromCoordinate(closePnt);
            position.style.left = pendp[0] - 6 + 'px';
            position.style.top = pendp[1] + 7 + 'px';

            this.topojsonLayer.setStyle(this.getLayerStyles());
        }
        if (this.markers.length == 1)
        {
          let L  = new LineString([this.markers[0], this.mousepos]);
          this.drawarcFeature.setGeometry(L);
          this.topojsonLayer.setStyle(this.getLayerStyles());
        }
        return;
      }
      var isTO = false;
      var coord = this.activeElement.getGeometry().getCoordinates();
      if (coord.length > 2) {
        coord = coord[0];
        isTO = true;
      }
      
      this.showPos(this.activeElement);
      
      if(this.action() == "rotate") {
         var a = this.GetBearing(coord, this.mousepos);
         this.activeElement.values_.angle = -a
        if(this.activeElement.values_.geometryReadings) {
          var centercoords = this.activeElement.getGeometry().getCoordinates()
          if(this.prevangle == 0) {
            var b = this.GetBearing(coord, centercoords[centercoords.length-1]);
            this.activeElement.getGeometry().rotate(b  * 180 / Math.PI > 180? -b: -b, centercoords[0]);
            this.topojsonLayer.setStyle(this.getLayerStyles());
          }
          this.activeElement.getGeometry().rotate(a - this.prevangle, centercoords[0]);
          this.prevangle = a;
        }
        this.topojsonLayer.setStyle(this.getLayerStyles());

      }
      if(this.action() == "move") {
        if(isTO) {
          var x = coord[0] - this.mousepos[0]-10;
          var y = coord[1] - this.mousepos[1]-10;
          var coords = [];
          this.activeElement.getGeometry().getCoordinates().forEach(coo => {
            coo[0] = coo[0] - x;
            coo[1] = coo[1] - y;
            coords.push(coo);
          });
          this.activeElement.getGeometry().setCoordinates(coords);
        } else {
          this.activeElement.getGeometry().setCoordinates([this.mousepos[0]-4,this.mousepos[1]-4]);
        }
        this.topojsonLayer.setStyle(this.getLayerStyles());

      }
    })

    this.map.on("click", (e) => {
      var coord = toLonLat(e.coordinate);


      if(this.action() == "rotate") {
        this.action.set("");
        this.prevangle = 0;
        let c :any  = {};
        c.id = this.activeElement.values_.ID;
        // var co = this.activeElement.getGeometry().getCoordinates();
        // this.activeElement.values_.angle = -(this.GetBearing(co[0], co[co.length-1]) - (6/180*Math.PI));
        c.angle = this.activeElement.values_.angle;
        this.activeElement.values_.isEdited = true;
        this.enableSave = true;
        this.apiservice
        .applyRotation(c)
        .pipe()
        .subscribe((res: any) => {
        });
      }

      if(this.action() == "drawarc") {
        if(this.markers.length == 3) {

          if(this.arcShowDlgOnly) {
            let src = this.topojsonLayer.getSource();
            src.removeFeature(this.drawarcFeature);
            this.drawarcFeature = undefined;
            this.isGeoPosModalVisible = true;
          } else {
            this.tempfeaturesList.push({ type: 'import_Line', feature: this.activeElement, geom: this.activeElement.getGeometry().clone().transform('EPSG:3857','EPSG:4326' ).getCoordinates() });
            this.enableSave = true;
          }
          this.markers = [];
          this.drawarcFeature = undefined;
          this.prevTarget.style.backgroundColor = "#F0F0F0";
          this.action.set("");

        }
        if(this.markers.length == 1) {
          this.markers.push(this.mousepos);
          this.markers.push(this.mousepos);

        }
        if(this.markers.length == 0) {
          this.markers.push(this.mousepos);
          this.drawarcFeature = new Feature(new LineString([this.markers[0], this.markers[0]]));
          this.drawarcFeature.setProperties({"layername": "objekttyp.0"});
          let src = this.topojsonLayer.getSource();
          src.addFeature(this.drawarcFeature);
        }

        return;
      }

      if(this.action() == "modify" && !this.altPressed) {
        this.map.removeInteraction(this.modify);
        this.map.addInteraction(this.hoverSelect);
        this.action.set("");
      }


      if(this.action() == "move") {
        this.action.set("");
        this.activeElement.values_.isEdited = true;
        this.enableSave = true;
      }


      if(this.action() == "cut" || this.action() == "separate") {
        let position = document.getElementById('position');
        if(position.style.visibility == "visible") {
          var closePnt = this.activeElement.getGeometry().getClosestPoint(this.mousepos);
          var coords = this.activeElement.getGeometry().getCoordinates();
          var lastPos = 1;
          var totalLen = 0;
          for (let i = 0; i < coords.length-1; i++) {
            var line = new LineString([coords[i], coords[i+1]]);
            totalLen += geoDesLength(line);
            if (totalLen < this.len) {
              lastPos = i+2;
            }
            else break;
          }
          if(lastPos > 0) {
            var tmp = this.activeElement.clone();
            this.map.getViewport().style.cursor = "grab";
            var splicedCoors = coords.splice(0,lastPos);
            splicedCoors.push(closePnt);
            this.activeElement.getGeometry().setCoordinates(splicedCoors);
            this.activeElement.values_.isEdited = true;
            this.enableSave = true;

            if (this.action() == "separate") {

              //Hard Copy von dem aktiven element, erste Koordinate muss schnittpunkt sein, dann umwandeln in EPSG 4326 (von m in °)
              coords = tmp.getGeometry().getCoordinates();
              coords = coords.slice(lastPos, coords.length);
              coords.splice(0, 0, closePnt);
              tmp.getGeometry().setCoordinates(coords);
              coords = tmp.getGeometry().transform('EPSG:3857', 'EPSG:4326').getCoordinates();

              let c: any = {};
              c.koordinaten = coords;
              c.typid = 1;
              this.apiservice
                .setNewFeature(c)
                .pipe()
                .subscribe((res: any) => {
                  let src = this.topojsonLayer.getSource();
                  let features = src.getFormat().readFeature(res, {
                    featureProjection: "EPSG:3857",
                  });
                  if(geoDesLength(features.getGeometry()) >= 1) { //Gleis muss min 1 meter lang sein
                    this.tempfeaturesList.push({ type: 'new_Line', feature: res });
                    src.addFeature(features);
                  }
                });
            }
          }

        }
        this.action.set("");
      }


      if(this.action() == "merge") {
        this.action.set("");
        if(this.hoverSelect.getFeatures().item(0) != this.activeElement) {
          if(this.hoverSelect.getFeatures().item(0).getGeometry().getType() == "LineString") {
            var lastCoord = this.activeElement.getGeometry().getCoordinates()[this.activeElement.getGeometry().getCoordinates().length-1];
            var curfeat : Feature<LineString> = this.hoverSelect.getFeatures().item(0);
            //Nächstes gewähltes Element drehen, falls nicht mit der Richtung des ursprünglichen Linestrings übereinstimmend.
            var lineEnd1 = new LineString([lastCoord, curfeat.getGeometry().getCoordinates()[0]]);
            var lineEnd2 = new LineString([lastCoord, curfeat.getGeometry().getCoordinates()[curfeat.getGeometry().getCoordinates().length-1]]);
            if(geoDesLength(lineEnd2) < geoDesLength(lineEnd1)) {
              curfeat = this.rotateLine(curfeat);
            }
            this.activeElement.getGeometry().setCoordinates(this.activeElement.getGeometry().getCoordinates().concat(curfeat.getGeometry().getCoordinates()));
            this.activeElement.values_.isEdited = true;
            this.enableSave = true;
            this.deleteFeature(curfeat.getProperties().ID,curfeat);
          }
        }
      }

      if(this.action() == "sort") {
        this.action.set("");
        let coords : Coordinate[] = this.activeElement.getGeometry().clone().getCoordinates();
        let closePnt = this.findNearestSegment(coords,this.mousepos);
        let out : Coordinate[] = [];//coords.slice(closePnt.index,coords.length);
        out.push(closePnt.coord);
        coords.splice(closePnt.index,1);
        //out = out.concat(coords.splice(closePnt.index,coords.length)).concat(coords);  //Alternativ-Lösung, evtl schneller, aber auch richtiger??
        let x = 0;
        for (let index = 0; index < coords.length; index++) {
          var closest = this.findNearestSegment(coords,out[out.length - 1]);
          out.push(closest.coord);
          coords.splice(closest.index,1);
          x += 1;
        }
        out = out.concat(coords);
        this.activeElement.getGeometry().setCoordinates(out);

      }


      if(this.action() == "place") {
        this.action.set("");
        this.prevTarget.style.backgroundColor = "#F0F0F0";
        this.prevTarget = undefined;
        this.map.getViewport().style.cursor = "grab";
        let c :any  = {};
        c.koordinaten = coord;
        c.name = this.curSymbol;
        this.apiservice
        .setNewSymbol(c)
        .pipe()
        .subscribe((res: any) => {
          let src = this.topojsonLayer.getSource();
          let features = src.getFormat().readFeature(res, {
            featureProjection: "EPSG:3857",
          });
          this.tempfeaturesList.push({type:'new_Symbol', feature: res});
          src.addFeature(features);

        });
      }
      if(this.action() == "placeTO") {
        let rotateAtEnd = false;
        this.action.set("");
        this.prevTarget.style.backgroundColor = "#F0F0F0";
        this.prevTarget = undefined;
        this.map.getViewport().style.cursor = "grab";
        var vals = [];
        vals.push(this.tb1);
        vals.push(this.tb2);
        vals.push(this.tb3);
        vals.push(this.tb4);
        vals.push(this.tb5);
        if(this.tb6 != '')vals.push(this.tb6);

        if(this.modalTOEditor.value.PositionMouse) {
          coord = this.activeElement.getGeometry().getClosestPoint(this.mousepos);
          coord = toLonLat(coord);
          rotateAtEnd = true;
        }
        if (this.modalTOEditor.value.PositionCut) {
          coord = this.cutLine(this.modalTOEditor.value.Position);
          rotateAtEnd = true;
        }

        let c :any  = {};
        c.messwerte = vals;
        c.koordinaten = coord;
        c.name = this.selTOType;
        this.apiservice
        .setNewTOSymbol(c)
        .pipe()
        .subscribe((res: any) => {
          let src = this.topojsonLayer.getSource();
          let features = src.getFormat().readFeature(res, {
            featureProjection: "EPSG:3857",
          });
          this.tempfeaturesList.push({type:'new_Symbol', feature: res});
          src.addFeature(features);

          if(rotateAtEnd){
            var prevCoord = features.getGeometry().clone().getCoordinates()[features.getGeometry().getCoordinates().length -1];
            var a = this.GetBearing(coord, prevCoord);
            features.values_.angle = -a
            features.getGeometry().rotate(a, coord);
            this.prevangle = a;
          }
        });

        this.modalTOEditor.value.PositionMouse = false;
        this.modalTOEditor.value.PositionCut = false;
        this.modalTOEditor.value.Position = undefined;
      }


      if(this.action() == "getPos") {
        this.mouseposConv = this.mousepos[0].toFixed(2) + ", " + this.mousepos[1].toFixed(2) + " / " + toLonLat(this.mousepos);
        this.action.set("");
        this.isGeoPosModalVisible = true;
      }

      if(this.action() == "measure") {
        if (this.measure.SetSecondPoint) {
          this.map.removeInteraction(this.draw);
          this.action.set("");
          this.measure.Point2 = [this.mousepos[0].toFixed(2) , this.mousepos[1].toFixed(2)]
          this.measure.Point2_Deg = toLonLat(this.mousepos)
          var line : LineString = new LineString([this.measure.Point1, this.measure.Point2])
          this.measure.PointX = (parseFloat(this.measure.Point2[0]) -  parseFloat(this.measure.Point1[0])).toFixed(2) + " m ";
          this.measure.PointY = (parseFloat(this.measure.Point2[1]) -  parseFloat(this.measure.Point1[1])).toFixed(2) + " m ";
          this.measure.Distance = geoDesLength(line).toFixed(2) + " m";
          this.isGeoPosModalVisible = true;


        } else {
          this.measure.SetSecondPoint = true;
          this.measure.Point1 = [this.mousepos[0].toFixed(2) , this.mousepos[1].toFixed(2)]
          this.measure.Point1_Deg =  toLonLat(this.mousepos)
        }

      }
      if(this.action() == "moveAll") {
        if (this.measure.SetSecondPoint) {
          this.map.removeInteraction(this.draw);
          this.action.set("");
          this.measure.Point2 = [this.mousepos[0].toFixed(2) , this.mousepos[1].toFixed(2)]
          this.measure.Point2_Deg = toLonLat(this.mousepos)
          var line : LineString = new LineString([this.measure.Point1, this.measure.Point2])
          this.measure.PointX = this.measure.Point2[0] -  this.measure.Point1[0];
          this.measure.PointY = this.measure.Point2[1] -  this.measure.Point1[1];
          this.measure.Distance = geoDesLength(line).toFixed(2) + " m";

          let src = this.topojsonLayer.getSource();
          let features = src.getFeatures();
          features.forEach(feat => {
            var newCoords = [];
            if(Array.isArray(feat.getGeometry().getCoordinates()[0])) {
              feat.getGeometry().getCoordinates().forEach(coord => {
                coord[0] = coord[0] + this.measure.PointX;
                coord[1] = coord[1] + this.measure.PointY;
                newCoords.push(coord);
              });
              feat.getGeometry().setCoordinates(newCoords);
            }
            else{
              coord = feat.getGeometry().getCoordinates();
              coord[0] = coord[0] + this.measure.PointX;
              coord[1] = coord[1] + this.measure.PointY;
              feat.getGeometry().setCoordinates([coord[0],coord[1]]);
            }
            //this.tempfeaturesList.push({ type: 'edit_Feature', feature: feat.getProperties(), geom: feat.getGeometry().transform('EPSG:3857', 'EPSG:4326').getCoordinates()})
          });
          this.topojsonLayer.setStyle(this.getLayerStyles());

        } else {
          this.measure.SetSecondPoint = true;
          this.measure.Point1 = [this.mousepos[0].toFixed(2) , this.mousepos[1].toFixed(2)]
          this.measure.Point1_Deg =  toLonLat(this.mousepos)
        }
      }


      if(this.prevTarget) {
         this.prevTarget.style.backgroundColor = "#F0F0F0";
          this.prevTarget = undefined;
          this.activeElement = undefined;
          this.modPointsLayer.getSource().clear();
          this.hoverSelect.getFeatures().clear();
          this.clickSelect.getFeatures().clear();
      }
    });

    this.clickSelect.on("select", (e) => {
      this.activeElement = undefined;
      this.showdetailview = false;
      this.progressbars = {};
      this.showprogressbar = false;
      this.ostammid = undefined;
      this.modPointsLayer.getSource().clear();
      this.clickSelect.getFeatures().clear();
      if (e.selected && e.selected.length > 0) {
        const map = e.target.getMap();
        const sel = e.selected[0];
        const id = sel.getProperties().OSTAMMID;
        const Delstatus = sel.getProperties().DelStatus;
        if (this.action() == "draw") return;
        if (id > 0 && (Delstatus == 0 || Delstatus == undefined)) {
          const lastId = this.ostammid;
          this.ostammid = id;
          this.activeElement = sel;

          const extent = sel.getGeometry().getExtent();
          const view = map ? map.getView() : this.map.getView();

          view.fit(extent, {
            duration: 500,
            maxZoom: 19,
            padding: [50, 100, 50, 100],
            callback: () => {
              if (lastId == undefined) view.setZoom(view.getZoom() - 1);
              this.clickSelect.getFeatures().push(sel);
              this.mapZoomed$.next(true);
              this.updateMapSize();
            },
          });
          this.hoverSelect.getFeatures().clear();
        } else {
          // this.toastr.warning(
          //   this.mrTranslate.transform(
          //     "Es liegen keine Daten zu diesem Objekt vor."
          //   )
          // );
          this.activeElement = sel;
          this.clickSelect.getFeatures().push(sel);
          this.mapZoomed$.next(true);
          this.updateMapSize();
          this.hoverSelect.getFeatures().clear();
        }
      } else {
        if (this.activeElement)
          this.clickSelect.getFeatures().push(this.activeElement);
      }
    });

    this.map.getInteractions().push(this.clickSelect);
    this.map.getInteractions().push(this.hoverSelect);

    //Cursor Grabber & Pointer
    this.hoverSelect.on("select", (e) => {
      if(this.action() != "" && this.action() != undefined ) {
        this.map.getViewport().style.cursor = "crosshair";
      }else{
        if (e.selected.length > 0) {
          this.map.getViewport().style.cursor = "crosshair";
        } else {
          this.map.getViewport().style.cursor = "crosshair";
          let info = document.getElementById('info');
          info.style.visibility = 'hidden';
        }
      }

    });

    this.map.getViewport().style.cursor = "crosshair";
    this.map.on("pointerdrag", function (evt) {
      evt.target.getViewport().style.cursor = "crosshair";
    });
    this.map.on("pointerup", function (evt) {
      evt.target.getViewport().style.cursor = "crosshair";
    });

    // Basemap on
    if (Object.keys(this.baseLayer).length == 0) {
       if (localStorage.getItem("theme") == "dark") {
          this.tileSwitch("cartoDB", "cartoDbDark");
       } else {
         this.tileSwitch("cartoDB", "cartoDbLight");
       }
    }
    else
    {
      this.tileSwitch(this.baseLayer[0], this.baseLayer[1]);
    }

    if (storeddata?.baseLayer && storeddata?.baseLayer != this.baseLayer) {
      this.tileSwitch(storeddata.baseLayer[0], storeddata.baseLayer[1]);
    }

    // handle geolocation error.
    this.geolocation.on('error', function (error) {
      console.log( error.message);
    });

    combineLatest([this.mapready$, this.topojsonready$])
      .pipe(
        filter(([mapready, topojsonready]) => mapready && topojsonready),
        first(),
        delay(200)
      )
      .subscribe(() => {
        console.debug("mapready and topojsonready");

        this.getStrValues(storeddata?.overLayer ?? undefined);
        const features = this.topojsonLayer.getSource().getFeatures();
        const storedElement = storeddata?.activeElement as Feature<Geometry>;
        const queryElement = this.route.snapshot.queryParams[
          "ostammid"
        ] as number;

        const activeElement = queryElement
          ? features.find((feature) => {
              const props = feature.getProperties();
              return (
                props?.layername &&
                props.layername.includes("objekttyp") &&
                props.OSTAMMID == queryElement
              );
            })
          : !storedElement
          ? undefined
          : features.find((feature) => {
              const props = feature.getProperties();
              return (
                props?.layername &&
                props.layername == storedElement.getProperties().layername &&
                props.OSTAMMID == storedElement.getProperties().OSTAMMID
              );
            });

        // console.log("activeElement", activeElement);

        if (activeElement == undefined && queryElement != undefined){
          this.toastr.warning(this.mrTranslate.transform("Objekt konnte nicht in der Karte gefunden werden"));
          return;
        }


        if (!view && !activeElement) {
          this.map.getView().fit(this.topojsonLayer.getSource().getExtent(), {
            duration: 500,
            padding: [50, 50, 50, 50],
            callback: () => {
              this.mapZoomed$.next(true);
            },
          });
        } else {
          if (activeElement) {
            this.selectFeature(activeElement);
          }
        }

        this.setupSearch();
      });
  }

  private setupSearch() {
    const click = this.clickSelect;
    // Set the control grid reference
    const placeholdertext = this.mrTranslate.transform("Suche") + "...";
    var search = new SearchFeature({
      label: "Objektsuche",
      placeholder: placeholdertext,
      //target: $(".options").get(0),
      //property: $(".options select").val(),
      property: "Bezeichnung",
      sort: function (f1, f2) {
        //@ts-ignore
        if (search.getSearchString(f1) < search.getSearchString(f2)) return -1;
        //@ts-ignore
        if (search.getSearchString(f1) > search.getSearchString(f2)) return 1;
        return 0;
      },
      getSearchString(f) {
        return f?.getProperties()?.Bezeichnung || "";
      },
    });
    //@ts-ignore
    search.setSource(this.topojsonLayer.getSource());

    this.map.addControl(search);

    // Select feature when click on the reference index
    search.on("select", (e) => {
      const feature = e.search;
      let map = e.target.getMap() as OlMap;

      click.getFeatures().clear();
      let lastId = this.ostammid as number;
      this.ostammid = feature.getProperties().OSTAMMID;

      let extent = feature.getGeometry().getExtent();
      let view = map.getView();
      let zoomto = () => {
        view.fit(extent, {
          duration: 500,
          maxZoom: 19,
          padding: [50, 100, 50, 100],
          callback: () => {
            if (lastId == undefined) view.setZoom(view.getZoom() - 1);
            click.getFeatures().push(feature);
          },
        });
      };
      zoomto();
    });
  }

  ngAfterViewInit(): void {
    this.apiservice
      .getSettingsApiKeys()
      .pipe()
      .subscribe((apikeys: any) => {
        this.initMap(apikeys);
      });
  }

  setDateFilter(dateFilterObj: any) {
    this.datefilter = dateFilterObj;
    this.topojsonLayer.setStyle(this.getLayerStyles());
  }

  restoreMapState(storeddata) {
    if (storeddata) {
      const routeHasOstammid =
        this.route.snapshot.queryParams["ostammid"] !== undefined;

      if (storeddata["showdetailview"]) {
        this.showdetailview = storeddata["showdetailview"];
      }
      if (storeddata["hidedetailview"]) {
        this.hidedetailview = storeddata["hidedetailview"];
      }

      if (storeddata["ostammid"]) {
        if (this.showdetailview) {
          this.ostammid = storeddata["ostammid"];
        } else {
          this.ostammid = undefined;
        }
      }

      if (
        storeddata["baseLayer"] &&
        storeddata["baseLayer"] != this.baseLayer
      ) {
        this.tileSwitch(storeddata["baseLayer"][0], storeddata["baseLayer"][1]);
      }

      if (!routeHasOstammid && storeddata["activeElement"]) {
        this.activeElement = undefined;
        const features = this.topojsonLayer.getSource().getFeatures();
        const elemProps = storeddata["activeElement"].getProperties();
        const feature = features.find((feature) => {
          const props = feature.getProperties();
          return (
            props?.layername &&
            props.layername == elemProps.layername &&
            props.OSTAMMID == elemProps.OSTAMMID
          );
        });

        if (feature) {
          this.selectFeature(feature);
        }
      } else {
        if (!routeHasOstammid && storeddata["view"]) {
          const savedView = storeddata["view"];
          const view = this.map.getView();
          view.setCenter(savedView.getCenter());
          view.setZoom(savedView.getZoom());
          view.setRotation(savedView.getRotation());
        }
      }

      if (storeddata["sidenavenlarged"]) {
        this.sidenavenlarged = storeddata["sidenavenlarged"];
      }
      if (storeddata["progressbarenlarged"]) {
        this.progressbarenlarged = storeddata["progressbarenlarged"];
      }
      if (storeddata["split50"]) {
        this.split50 = storeddata["split50"];
      }

      document
        .querySelector(".ol-viewport")
        .dispatchEvent(new MouseEvent("mousemove"));
    }
  }

  getStrValues(overlays) {
    this.apiservice
      .getKarteStrValues()
      .pipe()
      .subscribe((strValues: any) => {
        Object.keys(strValues).forEach((strVal) => {
          let cat, ovrl;
          if (strVal == "objekttyp") {
            let objTypes = strValues[strVal];
            if (objTypes?.length > 0) {
              objTypes.forEach((type) => {
                let key = `objekttyp.${type.OTYPID}`;
                this.overLayers.base[0].overlays.push({
                  name: type.Bezeichnung,
                  key: key,
                  icon: type.icon,
                  enable: false,
                  color: "black",
                });
                this.vectorLayerNames.push(key);
              });
            }
            return;
          }

          if (strVal.indexOf(".") != -1) {
            let strArr = strVal.split(".");
            cat = strArr[0].toLowerCase();
            ovrl = strArr[1];
          } else {
            cat = strVal;
            ovrl = strVal;
          }

          if (!this.overLayers.visualisations[cat]) {
            this.overLayers.visualisations[cat] = [];
          }

          let ind = -1;
          for (var e = 0; e < this.overLayers.visualisations[cat].length; e++) {
            if (
              this.overLayers.visualisations[cat][e] &&
              this.overLayers.visualisations[cat][e].category &&
              this.overLayers.visualisations[cat][e].category == ovrl
            ) {
              ind = e;
              break;
            }
          }

          if (ind == -1) {
            let catName = ovrl.charAt(0).toUpperCase() + ovrl.slice(1);
            this.overLayers.visualisations[cat].push({
              category: catName,
              overlays: [],
            });
            ind = this.overLayers.visualisations[cat].length - 1;
          }

          strValues[strVal].forEach((keyEl: any) => {
            let key = (strVal + "." + keyEl.val).trim();
            this.overLayers.visualisations[cat][ind].overlays.push({
              name: keyEl.val,
              key: key,
              enable: false,
              color: keyEl.color,
            });
            this.visuColors[key] = keyEl.color;
            this.vectorLayerNames.push(key);
          });
        });

        if (overlays && overlays.length > 0) {
          overlays.forEach((overlay: any) => {
            this.changeOverlayState(overlay[0], overlay[1], overlay[2], true);
          });
        }

        for (let i = 0; i < this.overLayers.base[0].overlays.length; i++) {
          this.changeOverlayState("base", 0, i, true);
        }
      });
  }

  colorFocusInput(input: HTMLInputElement) {
    input.focus();
    input.click();
  }

  setColorFromPicker(group: string, i: number, j: number, event) {
    let ol = this.overLayers.base;
    if (group != "base") {
      ol = this.overLayers.visualisations[group];
    }

    let key = ol[i].overlays[j].key;
    this.visuColors[key] = event;
    this.topojsonLayer.setStyle(this.getLayerStyles());
  }
  setColorGeneral(type: string, event) {
    this[type] = event;
    this.topojsonLayer.setStyle(this.getLayerStyles());
    this.persistanceService.set("karteFarbe", { "bC": this.baseColor, "hC": this.hoverColor, "hEC": this.hoverEmptyColor });
  }

  resetColor() {
    let dark = this.baseLayer[1] == "cartoDbDark" || this.baseLayer[1] == "cartoDbDarkNoLabels" || this.baseLayer[1] == "mapboxDark";
    this.baseColor = dark ? "rgba(170,170,170,0.6)" : "rgba(0,0,0,0.6)";
    this.hoverColor = "#fe5d00";
    this.hoverEmptyColor = "#FEAC88";
    this.topojsonLayer.setStyle(this.getLayerStyles());
    this.persistanceService.set("karteFarbe", { "bC": this.baseColor, "hC": this.hoverColor, "hEC": this.hoverEmptyColor });
  }

  applyFilter(event) {
    this.objectFilter = event;
    this.topojsonLayer.setStyle(this.getLayerStyles());
  }

  invertFilter(event) {
    this.filter_invert = event;
    this.topojsonLayer.setStyle(this.getLayerStyles());
  }

  setMessFilter(event) {
    this.spinner.enable();
    this.isGleisMessFilterReady = false;
    this.gleisMessFilter = event;
    this.visumode = ["measurements", 0];
    this.disableAllOverlays(this.visumode);
    this.gleisMessLayer.getSource().refresh();
  }

  getLayerStyles() {
    let dark =
      this.baseLayer[1] == "cartoDbDark" ||
      this.baseLayer[1] == "cartoDbDarkNoLabels" ||
      this.baseLayer[1] == "mapboxDark";
      this.baseColor = this.baseColor;

    let getFKColor = function (fk: any) {
      let color = "black";
      switch (parseInt(fk)) {
        case 1:
          color = "red";
          break;
        case 2:
          color = "yellow";
          break;
        case 3:
          color = "green";
          break;
        case 4:
          color = "blue";
          break;
        default:
          break;
      }
      return color;
    };

    return (feature, resolution) => {
      let prop = feature.getProperties();

      if (this.objectFilter && this.objectFilter.length > 0) {
        if (!this.filter_invert) {
          if (this.objectFilter.indexOf(prop.OSTAMMID) == -1) {
            return null;
          }
        } else {
          if (
            !prop.OSTAMMID ||
            this.objectFilter.indexOf(prop.OSTAMMID) != -1
          ) {
            return null;
          }
        }
      }

      if (this.datefilter.vom != null && this.datefilter.bis != null) {
        if (prop.filterdate) {
          // if (
          //   !dayjs(prop.filterdate).isBetween(
          //     dayjs(this.datefilter.vom),
          //     dayjs(this.datefilter.bis)
          //   )
          // ) {
          //   return null;
          // }
        }
      }

      let show = false;
      if (prop.layername) {
        let isBaseLayer = prop.layername.indexOf("objekttyp.") != -1;
        if (!isBaseLayer) {
          let otypid = prop.OTYPID;
          let typOvrl =
            this.overLayers.base[0].overlays.find(
              (ovrl) => ovrl.key.indexOf(otypid) != -1
            ) || undefined;
          let showOTYPID = typOvrl && typOvrl.enable == true ? true : false;
          if (!showOTYPID) return null;
        }

        if (prop.layername.indexOf(",") != -1 && this.visuColors) {
          let multi = prop.layername.split(",");
          show = false;
          let color = "#800080";
          for (var j = 0; j < multi.length; j++) {
            if (!show && this.vectorLayerNames.indexOf(multi[j]) == -1) {
              color = this.visuColors[multi[j]];
              show = color ? true : false;
              break;
            }
          }
          if (!show) {
            return null;
          } else {
            this.elementStyles.line.getStroke().setColor(color);
            this.elementStyles.line.getStroke().setLineDash([]);
            this.elementStyles.line.setZIndex(1);
            return this.elementStyles.line;
          }
        } else {
          let ind = this.vectorLayerNames.indexOf(prop.layername);
          if (ind != -1) {
            return null;
          }
        }

          if(isBaseLayer) {
            if (
              prop.geometry.getType() == "LineString" ||
              prop.geometry.getType() == "MultiLineString"
            ) {
              this.elementStyles.line.getStroke().setColor(this.baseColor);
              this.elementStyles.line.getStroke().setLineDash([]);
              this.elementStyles.line.setZIndex(0);
              if (this.showObjText && prop.Name != null)  {
                let name = prop.Name;
                   this.elementStyles.line.setText(
                    new OlText ({
                    text: name,
                    font: "Arial bold",
                    //offsetY: 12,
                    //offsetX: 12,
                    backgroundFill: new OlFill({color: "lightgreen"}),
                    scale: 1.2,
                    textAlign: 'center',
                    justify: 'center'
                  })
                );
              }else {
                this.elementStyles.line.setText(null);
              }
              // }else {
              //   this.elementStyles.line.setImage(null);
              // }
              return this.elementStyles.line;
            }
            if (prop.geometry.getType() == "Point" && prop.PointID)
            {
              let smallIcon = prop.PointID == "5" || prop.PointID == "23" || (prop.PointID >= "30" && prop.PointID <= "41");
              let imagestl = new Icon({
                src: "assets/icons/GM_Typ_" + prop.PointID + ".svg",
                scale: smallIcon? 0.2 / resolution : 0.3 / resolution,
                rotation: prop.angle,
                anchor: smallIcon? [0.5,0.5]: [0.5,1.0],
                color: this.baseColor
              });
              this.elementStyles.point.setImage(imagestl);
              return this.elementStyles.point;
            }
            if (prop.geometry.getType() == "Point" && prop.OSTAMMID > 1){
              this.elementStyles.point.setImage(
                new CircleStyle({
                  radius: 4,
                  stroke: new OlStroke({
                    color: dark ? "rgba(170,170,170,0.6)" : "rgba(0,0,0,0.6)",
                    width: 2,
                  }),
                  fill: new OlFill({ color:this.baseColor})//color: dark ? "rgba(170,170,170,0.6)" : "rgba(0,0,0,0.6)", }),
                })
              );
              return this.elementStyles.point;
            }


            return null;
          }
          if(prop.layername.indexOf("maengel") != -1 ||
            prop.layername.indexOf("minfk") != -1) {
            let errorNum = prop.layername.match(/\d+/)[0];
            let color = getFKColor(errorNum);
            if (
              prop.geometry.getType() == "LineString" ||
              prop.geometry.getType() == "MultiLineString"
            ) {
              // Wenn "Linienobjekte deaktiviert: keine Linien Visus rendern"
              this.elementStyles.line.getStroke().setColor(color);
              this.elementStyles.line.getStroke().setLineDash([]);
              this.elementStyles.line.setZIndex(6 - errorNum);
              if (this.showObjText && prop.Name != null)  {
                this.elementStyles.line.setText(
                  new OlText ({
                  text: prop.Name ,
                  font: "Arial bold",
                  //offsetY: 12,
                  //offsetX: 12,
                  textAlign: 'center',
                  justify: 'center',
                  backgroundFill: new OlFill({color: "lightgreen"}),
                  scale: 1.2
                })
              );
            }else {
              this.elementStyles.line.setText(null);
            }
              return this.elementStyles.line;
            } else {
              // Wenn "Punktobjekte deaktiviert: keine Punkte Visus rendern"
              this.elementStyles.point.setImage(
                new CircleStyle({
                  radius: 4,
                  stroke: new OlStroke({
                    color: dark ? "rgba(170,170,170,0.6)" : "rgba(0,0,0,0.6)",
                    width: 2,
                  }),
                  fill: new OlFill({ color: color }),
                })
              );

              return this.elementStyles.point;
            }
          }
          if(prop.layername.indexOf("stoerungen") != -1 ||
            prop.layername.indexOf("stamm") != -1 ||
            prop.layername.indexOf("sperrungen") != -1 ||
            prop.layername.indexOf("langsamfahrstellen") != -1 ||
            prop.layername.indexOf("trassierungen") != -1 ||
            prop.layername.indexOf("prueftermin") != -1 ||
            prop.layername.indexOf("auftraege") != -1 ||
            prop.layername.indexOf("kaveinfach") != -1 ||
            prop.layername.indexOf("kavseg") != -1 ||
            prop.layername.indexOf("sonstige") != -1 ||
            prop.layername.indexOf("projekte") != -1) {
            let col = this.visuColors[prop.layername];
            if (!col) {
              return null;
            }
            if (
              prop.geometry.getType() == "LineString" ||
              prop.geometry.getType() == "MultiLineString"
            ) {
              // Wenn "Linienobjekte deaktiviert: keine Linien Visus rendern"
              //if (!this.overLayers.base[0].overlays[0].enable) return null;
              this.elementStyles.line.getStroke().setColor(col);
              this.elementStyles.line.getStroke().setLineDash([]);
              this.elementStyles.line.setZIndex(1);
              if (this.showObjText && prop.Name != null)  {
                this.elementStyles.line.setText(
                  new OlText ({
                  text: prop.Name ,
                  font: "Arial bold",
                  //offsetY: 12,
                  //offsetX: 12,
                  textAlign: 'center',
                  justify: 'center',
                  backgroundFill: new OlFill({color: "lightgreen"}),
                  scale: 1.2
                }));
              }else {
                this.elementStyles.line.setText(null);
              }

              if (this.showMangelBem && prop.Bemerkung)  {
                this.elementStyles.line.getStroke().setColor("red");
                this.elementStyles.line.getStroke().setLineDash([6, 6]);
                this.elementStyles.line.setZIndex(6);
              }

              return this.elementStyles.line;
            } else {
              // Wenn "Punktobjekte deaktiviert: keine Punkte Visus rendern"
              //if (!this.overLayers.base[0].overlays[1].enable) return null;
              this.elementStyles.point.setImage(
                new CircleStyle({
                  radius: 4,
                  stroke: new OlStroke({
                    color: dark ? "rgba(170,170,170,0.6)" : "rgba(0,0,0,0.6)",
                    width: 2,
                  }),
                  fill: new OlFill({ color: col }),
                })
              );
              return this.elementStyles.point;
            }
          }
          return null;
        }
    };
  }

  disableAllOverlays(exceptVisuMode: any) {
    for (let i = 1; i < this.overLayers.base.length; i++) {
      if (exceptVisuMode[0] == "base") {
        if (i != exceptVisuMode[1]) {
          let j = 0;
          for (let ovrl of this.overLayers.base[i].overlays) {
            if (ovrl.enable === true) {
              this.changeOverlayState("base", i, j, false);
            }
            j++;
          }
        }
      } else {
        let j = 0;
        for (let ovrl of this.overLayers.base[i].overlays) {
          if (ovrl.enable === true) {
            this.changeOverlayState("base", i, j, false);
          }
          j++;
        }
      }
    }

    //let i = 0;
    for (let grp of Object.keys(this.overLayers.visualisations)) {
      this.overLayers.visualisations[grp].forEach((cat, i) => {
        if (
          !(grp == exceptVisuMode[0] && i == exceptVisuMode[1]) &&
          !(grp == "kavseg" && exceptVisuMode[0] == "kavseg")
        ) {
          let j = 0;
          for (let ovrl of cat.overlays) {
            if (ovrl.enable === true) {
              this.changeOverlayState(grp, i, j, false);
            }
            j++;
          }
        }
      });
    }

    if (exceptVisuMode[0] != "measurements") {
      this.gleisMessFilter = {};
      this.gleisMessLayer.getSource().refresh();
    }
  }

  changeOverlayState(group: string, i: number, j: number, event) {
    let evt = !!event;
    let ol = this.overLayers;

    let newVisumode = [group, i];
    if (
      this.visumode &&
      evt &&
      !(group == "base" && i == 0) &&
      JSON.stringify(this.visumode) != JSON.stringify(newVisumode)
    ) {
      this.disableAllOverlays(newVisumode);
      this.visumode = newVisumode;
    }

    if (group == "base") {
      ol = this.overLayers.base;
    } else {
      ol = this.overLayers.visualisations[group];
    }

    if (!(ol[i].overlays[j].enable !== false && evt !== false)) {
      var key: string;
      key = ol[i].overlays[j].key;

      if (!key.startsWith("objekttyp.") && event == 1) {
        this.apiservice
          .getLayerFeatures(this.curMapSet, key)
          .pipe()
          .subscribe((res: any) => {
            let src = this.topojsonLayer.getSource();
            let features = src.getFormat().readFeatures(res, {
              featureProjection: "EPSG:3857",
            });
            src.addFeatures(features);
            this.Featurecache.set(key,features);


            if (evt == true) {
              if (!(group == "base" && i == 0)) {
                this.visumode = newVisumode;
              }
              this.overLayIds.push([group, i, j]);
              this.vectorLayerNames.splice(this.vectorLayerNames.indexOf(key), 1);
            } else {
              if (this.vectorLayerNames.indexOf(key) == -1) {
                this.overLayIds.splice(this.overLayIds.indexOf([group, i, j]), 1);
                this.vectorLayerNames.push(key);
              }
            }
            let newStyle = this.getLayerStyles();
            this.topojsonLayer.setStyle(newStyle);
            ol[i].overlays[j].enable = evt;
          });
      } else {
        if(event == 0 && this.Featurecache.has(key)) {
          let src = this.topojsonLayer.getSource();
          for (let index = 0; index < this.Featurecache.get(key).length; index++) {
            const element = this.Featurecache.get(key)[index];
            src.removeFeature(element);
          }
          this.Featurecache.delete(key);

        }
        if (evt == true) {
          if (!(group == "base" && i == 0)) {
            this.visumode = newVisumode;
          }
          this.overLayIds.push([group, i, j]);
          this.vectorLayerNames.splice(this.vectorLayerNames.indexOf(key), 1);
        } else {
          if (this.vectorLayerNames.indexOf(key) == -1) {
            this.overLayIds.splice(this.overLayIds.indexOf([group, i, j]), 1);
            this.vectorLayerNames.push(key);
          }
        }
        let newStyle = this.getLayerStyles();
        this.topojsonLayer.setStyle(newStyle);
        ol[i].overlays[j].enable = evt;
      }
    }
  }

  setMangelBem(event : any) {
    this.showMangelBem = event.target.checked;
    let newStyle = this.getLayerStyles();
      // this.topojsonLayer.setStyle(newStyle);
      this.changeOverlayState('sonstige',0,0,this.showMangelBem)
  }
  setObjText(event : any) {
    this.showObjText = event.target.checked;
    let newStyle = this.getLayerStyles();
      this.topojsonLayer.setStyle(newStyle);
  }
  showPosiiton(event : any) {
    const chkd = event.target.checked;

    this.geolocation.setProjection(this.curEPSG);
    this.geolocation.setTracking(chkd);

    let src = this.topojsonLayer.getSource();
    if(chkd) {
      src.addFeature(this.positionFeature);
      this.positionStyle.setImage(
        new Icon({
          src: "assets/icons/person1.svg",
          scale: 0.2,
          anchor: [0.5,1.0]
        })
      );

      this.positionFeature.setStyle(this.positionStyle);
      this.geolocation.on('change:position', (ev) => {
        if(ev.target)
        {
          const coord = fromLonLat(ev.target.getPosition());
          this.positionFeature.setGeometry(coord ? new Point(coord) : null);

          if(!this.setPosFeatOnce) {
            var pnt = new Point(this.positionFeature.getGeometry().getExtent());
            this.map.getView().fit(
              pnt, {padding: [170, 50, 30, 150], minResolution: 6}
            );
            this.setPosFeatOnce = true;
          }
          
        }
      });
      this.geolocation.changed();
    }
    else {
      this.setPosFeatOnce = false;
      src.removeFeature(this.positionFeature);
    }


  }

  tileSwitch(tileLayerCat: string, tileLayerKey: string) {
    Object.keys(this.tileLayers).forEach((catname) => {
      Object.keys(this.tileLayers[catname]).forEach((layername) => {
        try{
        this.tileLayers[catname][layername].setVisible(false);
        } catch  {}
      });
    });
    this.tileLayers[tileLayerCat][tileLayerKey].setVisible(true);
    this.baseLayer = [tileLayerCat, tileLayerKey];
    const newStyle = this.getLayerStyles();
    this.topojsonLayer.setStyle(newStyle);

    this.persistanceService.set("karte", { "p": this.baseLayer[0], "m": this.baseLayer[1] });
    // console.log(this.persistanceService.get("karte"));

  }

  isTileLayerChecked(key, k) {
    return JSON.stringify([key, k]) == JSON.stringify(this.baseLayer);
  }

  triggerOverlaySwitcher() {
    this.showOverlaySwitcher = !this.showOverlaySwitcher;
  }

  enlargeSidenav(event) {
    if (event.mode == "full") {
      this.sidenavenlarged = event.val;
    } else if (event.mode == "half") {
      this.split50 = event.val;
    } else if (event.mode == "progress") {
      this.showprogressbar = event.val;
      this.progressbars = {};
    } else if (event.mode == "hidden") {
      this.hidedetailview = event.val;
    } else if (event.mode == "close") {
      this.split50 = false;
      this.sidenavenlarged = false;
      this.showdetailview = event.val;
      this.progressbars = {};
      this.showprogressbar = event.val;
      this.ostammid = undefined;
      this.activeElement = undefined;
      this.clickSelect.getFeatures().clear();
    }
  }

  enlargeProgressbar(val?) {
    if (val) {
      this.progressbarenlarged = true;
    } else {
      this.progressbarenlarged = !this.progressbarenlarged;
    }
    setTimeout(() => {
      this.updateMapSize();
    });
  }

  showProgressData(data) {
    if (data) {
      this.progressbars = data;
      this.showprogressbar = true;
    }
  }

  handleScrub(event) {
    this.indexScrub = event;
  }

  reloadDetailview() {
    const tmp = this.ostammid;
    this.ostammid = undefined;
    setTimeout(() => {
      this.ostammid = tmp;
    }, 100);
  }
  camelCaseToSentence(str: string) {
    return (
      str.charAt(0).toUpperCase() +
      str
        .replace(/([A-Z]+)/g, " $1")
        .replace(/([A-Z][a-z])/g, " $1")
        .slice(1)
    );
  }

  underscoreToSentence(str: string) {
    return (
      str.charAt(0).toUpperCase() +
      str
        .replace(/_[a-z]/g, function (v) {
          return v.toUpperCase();
        })
        .replace("_", " ")
        .slice(1)
    );
  }

  correctStammName(str: String) {
    if (str == "Plannr") {
      return "PlanNr";
    }
    let ret = str;
    ret = ret.replace(/ae/g, "ä");
    ret = ret.replace(/ue/g, "ü");
    ret = ret.replace(/oe/g, "ö");
    return ret;
  }

  getMonthName(month: number) {
    var returnMonth;

    switch (month) {
      case 1:
        returnMonth = "Januar";
        break;
      case 2:
        returnMonth = "Februar";
        break;
      case 3:
        returnMonth = "März";
        break;
      case 4:
        returnMonth = "April";
        break;
      case 5:
        returnMonth = "Mai";
        break;
      case 6:
        returnMonth = "Juni";
        break;
      case 7:
        returnMonth = "Juli";
        break;
      case 8:
        returnMonth = "August";
        break;
      case 9:
        returnMonth = "September";
        break;
      case 10:
        returnMonth = "Oktober";
        break;
      case 11:
        returnMonth = "November";
        break;
      case 12:
        returnMonth = "Dezember";
        break;
    }
    return returnMonth;
  }

  private GetPointsOfArc(points : Vector[])
{


    var a = points[0];
    var b = points[1];
    var c = points[2];
    let d = 2 * (a[0] - c[0]) * (c[1] - b[1]) + 2 * (b[0] - c[0]) * (a[1] - c[1]);
    let m1 = Math.pow(a[0], 2) - Math.pow(c[0], 2) + Math.pow(a[1], 2) - Math.pow(c[1], 2);
    let m2 = Math.pow(c[0], 2) - Math.pow(b[0], 2) + Math.pow(c[1], 2) - Math.pow(b[1], 2);
    let nx = m1 * (c[1] - b[1]) + m2 * (c[1] - a[1]);
    let ny = m1 * (b[0] - c[0]) + m2 * (a[0] - c[0]);
    let cx = nx / d;
    let cy = ny / d;
    let dx = cx - a[0];
    let dy = cy - a[1];
    let distance = Math.sqrt(dx * dx + dy * dy);
    var va1 = new Vector(a[0] - cx, a[1] - cy);
    var vb1 = new Vector(b[0] - cx, b[1] - cy);
    var vc1 = new Vector(c[0] - cx, c[1] - cy);
    var xaxis1 = new Vector(1, 0);
    let startAngle = this.AngleBetween(xaxis1, va1);
    let sweepAngle = this.AngleBetween(va1, vb1) + this.AngleBetween(vb1, vc1);

    let NumersOfSegmets = this.GetNumberOfSegments(cx, cy, distance, startAngle, sweepAngle);
    let PointsLocal = this.PointsOnCircleSector(cx, cy, distance, startAngle, sweepAngle, NumersOfSegmets);
    let ARCCenterPoint = toLonLat([cx, cy]);

    var L = [];

    for (let i = 0; i <= PointsLocal.length - 1; i++) {
      let pn = [PointsLocal[i].X, PointsLocal[i].Y];
      L.push(pn);
    }

    return L;
  }

  AngleBetween(v1: Vector, v2: Vector): number {
    let sin = v1.X * v2.Y - v2.X * v1.Y;
    let cos = v1.X * v2.X + v1.Y * v2.Y;
    return Math.atan2(sin, cos) * (180 / Math.PI);
  }

  private GetNumberOfSegments(cx, cy, radius, StartAngle, SweepAngle) {
    let alpha = 180 + StartAngle;
    let LenghtOfSegments = 100000;
    let InternNumberOfSegments = 9;

    while (LenghtOfSegments > 2) {
      var p = this.CalcPoint(cx, cy, alpha, radius);
      let p0: Coordinate = toLonLat([p.X, p.Y]);
      p = this.CalcPoint(cx, cy, alpha + SweepAngle / InternNumberOfSegments, radius);
      let p1: Coordinate = toLonLat([p.X, p.Y]);
      let line = new LineString([p0,p1]);
      LenghtOfSegments = geoDesLength(line) * 1000;

      InternNumberOfSegments += 1;
    }

    return InternNumberOfSegments - 1;

  }

  private PointsOnCircleSector(cx: number, cy: number, radius: number, StartAngle: number, SweepAngle: number, NumberOfsegments: number) {
    var Points = [];
    let alpha = 180 + StartAngle;

    for (let i = 0; i <= NumberOfsegments; i++) {
      Points.push(this.CalcPoint(cx, cy, alpha, radius));
      alpha = alpha + SweepAngle / NumberOfsegments;
    }

    return Points;

  }

  private CalcPoint(cx: number, cy: number, alpha: number, radius: number): Vector {
    let cos = Math.cos((alpha * 2) * Math.PI / 360);
    let sin: number = Math.sin((alpha * 2) * Math.PI / 360);
    let x = cos * radius;
    let y = sin * radius;
    return new Vector(cx - x, cy - y);
  }
}
class Vector
{
    X : number;
    Y: number ;

    constructor(xValue: number, yValue: number)
    {
        this.X = xValue;
        this.Y = yValue;
    }
}
